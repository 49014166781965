import Enum from "@utils/enum";

export const GROUP_ENUM = Enum({
  CHANNELS: "channels",
  CHAT: "chat",
});

export const USERS_IN_ROOM_TYPE = Enum({
  ADD: "Add",
  REMOVE: "Remove",
});

export const CHAT_SIDE_BAR_KEYS = Enum({
  CHAT: "CHAT",
  BROADCAST: "BROADCAST",
  MUTE: "MUTE",
  ARCHIVED: "ARCHIVED",
});

export const CHAT_SIDE_BAR_ITEMS = [
  {
    icon: `/static/images/icons/chat-icon.png`,
    key: CHAT_SIDE_BAR_KEYS.CHAT,
    label: "Chats",
    newChatLabel: "New Message",
  },
  {
    icon: `/static/svg/ic_broadcast.svg`,
    key: CHAT_SIDE_BAR_KEYS.BROADCAST,
    label: "Broadcasts",
    newChatLabel: "New Broadcast",
  },
  // {
  //   icon: `/static/svg/ic_mute.svg`,
  //   key: CHAT_SIDE_BAR_KEYS.MUTE,
  //   label: "Muted Chats",
  // },
  // {
  //   icon: `/static/svg/ic_archived.svg`,
  //   key: CHAT_SIDE_BAR_KEYS.ARCHIVED,
  //   label: "Archived Chats",
  // },
];
