import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.initial.main,
    color: theme.palette.grey.text,
    fontSize: 14,
    boxShadow: "1px 2px 5px 0 rgba(0,0,0,0.2)",
  },
  arrow: {
    color: theme.palette.initial.main,
  },
}))(Tooltip);

export const OutlinedTooltip = withStyles((theme) => ({
  tooltip: {
    color: "#4A4A4A",
    backgroundColor: "#F1F1F1",
    padding: "4px 10px",
    border: "1px solid #4A4A4A",
    fontWeight: 400,
    fontSize: 12,
    borderRadius: 6,
    textAlign: "center",
    maxWidth: 190,
  },
  arrow: {
    color: "#F1F1F1",
    "&::before": {
      border: "1px solid #4A4A4A",
    },
  },
  tooltipPlacementBottom: {
    margin: "2px 0",
  },
}))(Tooltip);
