import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";
import { FruProButton, FruProTextField } from "@components";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      maxWidth: 740,
    },
  },
  dialogContent: {
    width: "740px",
    padding: "0 16px",
    paddingTop: 0,
    "& .description": {
      color: "#858688",
      fontSize: 15,
      paddingBottom: 24,
    },
    "& .custom-textfield": {
      "& .MuiInputBase-root": {
        background: "#ffffff",
        height: 42,
        fontSize: 17,
        fontWeight: 500,
      },
      "& .MuiInput-underline:before": {
        borderColor: "#E8E8E8",
      },
    },
  },
  contentRows: {
    overflow: "auto",

    "& p": {
      margin: 0,
    },
  },
  contentRow: {
    display: "flex",
    padding: "8px 16px",
    alignItems: "center",
    borderBottom: "1px solid #F3F3F3",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey.light,
    },
  },
  contentSearch: {
    width: "100%",
    padding: "0 16px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      height: "50px",
    },
    marginBottom: 16,
  },
  dialogTitle: {
    padding: "24px 16px",
    justifyContent: "center",
  },
  dialogTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inline: {
    display: "inline",
  },
  employeeInfor: {
    paddingTop: 16,
    paddingBottom: 16,
    marginLeft: 16,
  },
  emailPhone: {
    fontSize: 12,
    paddingTop: 4,
    color: "#8B8B8B",
  },
  disabled: {
    backgroundColor: theme.palette.grey.light,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  dialogActions: {
    padding: "24px 16px",
  },
  textButton: {
    textTransform: "capitalize",
    textDecoration: "underline",
    fontSize: 17,
    fontWeight: "bold",
    marginRight: 28,
    minWidth: 121,
  },
  customPrimaryButton: {
    "&.MuiButton-root": {
      fontSize: 17,
      margin: 0,
      padding: "12px 16px",
      width: 153,
      maxHeight: 44,
      borderRadius: 3,
    },
  },
}));

export default function RenameChannelDialog({
  currentChannelName,
  openDialog,
  handleCloseDialog,
  handleRename,
  isBroadcastChannel,
}) {
  const classes = useStyles();

  const [channelName, setChannelName] = useState("");

  useEffect(() => {
    if (openDialog) setChannelName(currentChannelName);
    else setChannelName("");
  }, [openDialog]);

  const handleInputChange = (e) => {
    const channelName = e.target.value;
    setChannelName(channelName);
  };

  return (
    <Dialog
      className={classes.root}
      open={openDialog}
      onClose={() => handleCloseDialog()}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className={classes.dialogTitle}
      >
        <Grid item className={classes.dialogTitleContent}>
          <Typography className={classes.inline} variant="h6">
            {isBroadcastChannel
              ? "Change broadcast list name"
              : "Change group name"}
          </Typography>
        </Grid>
      </Grid>

      <DialogContent className={classes.dialogContent}>
        <Typography className="description" align="center">
          {isBroadcastChannel
            ? "Changing the name of a broadcast list only changes it for yourself."
            : "Changing the name of a group chat changes it for everyone."}
        </Typography>
        <FruProTextField
          className="custom-textfield"
          placeholder="Chat name"
          onChange={handleInputChange}
          value={channelName}
          fullWidth
          InputProps={{
            disableUnderline: false,
          }}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.textButton}
          onClick={() => handleCloseDialog()}
          variant="text"
          color="primary"
        >
          Cancel
        </Button>
        <FruProButton
          className={classes.customPrimaryButton}
          onClick={() => handleRename(channelName)}
          color="primary"
        >
          Save
        </FruProButton>
      </DialogActions>
    </Dialog>
  );
}
