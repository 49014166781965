import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  IconButton,
  DialogActions,
  Typography,
  Avatar,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  Close as CloseIcon,
  MoreHoriz as MoreIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { FruProButton, FruProActionDropdown, FruProDialog } from "@components";
import clsx from "clsx";
import { sortBy } from "lodash";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    width: "580px",
    height: "334px",
    padding: 0,
  },
  contentRows: {
    overflow: "auto",

    "& p": {
      margin: 0,
    },
  },
  contentRow: {
    display: "flex",
    padding: "8px 16px",
    alignItems: "center",
    borderBottom: "1px solid #F3F3F3",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey.light,
    },
    "&:hover $optionBtn": {
      visibility: "visible",
      width: 32,
      height: 32,
      borderRadius: "50%",
    },
  },
  optionBtn: {
    width: 32,
    height: 32,
    borderRadius: "50%",
  },
  hiddenBtn: {
    visibility: "hidden",
    marginLeft: 4,
  },
  contentSearch: {
    width: "100%",
    padding: "0 16px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      height: "50px",
    },
    marginBottom: 16,
  },
  dialogTitle: {
    padding: "16px",
  },
  dialogTitleContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inline: {
    display: "inline",
  },
  employeeInfor: {
    paddingTop: 16,
    paddingBottom: 16,
    marginLeft: 16,
    flex: 1,
  },
  fullName: {
    fontSize: 15,
    fontWeight: "bold",
  },
  roleLabel: {
    fontSize: 12,
  },
  disabled: {
    backgroundColor: theme.palette.grey.light,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  deleteContent: {
    padding: `${theme.spacing(4)}px 60px`,
  },
}));

export default function GroupUsersDialog({
  openDialog,
  handleCloseDialog,
  handleChangeAdmin,
  handleRemoveUserFromChannel,
  membersInfo = {},
  adminIds = [],
  currentUserId,
  isBroadcastChannel,
}) {
  const classes = useStyles();
  const [keyword, setKeyWord] = useState("");
  const [showDelDialog, setShowDelDialog] = useState(false);
  const [showLeaveDialog, setShowLeaveDialog] = useState(false);
  const [removedUser, setRemovedUser] = useState();

  const isAdmin = adminIds?.includes(currentUserId);

  const handleInputChange = (e) => {
    const keyword = e.target.value;
    setKeyWord(keyword.toLowerCase());
  };

  const listMembers = useMemo(() => {
    let data = Object.keys(membersInfo)?.map((key) => membersInfo[key]);
    let trimmedKeyword = keyword.trim();
    if (!trimmedKeyword) return data;
    else {
      return data.filter((member) =>
        member.fullName?.toLowerCase().includes(trimmedKeyword.toLowerCase())
      );
    }
  }, [membersInfo, keyword]);

  const sortedListMembers = useMemo(() => {
    let result = sortBy(listMembers, ["fullName"]);
    return result;
  }, [listMembers]);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => {
          setKeyWord("");
          handleCloseDialog();
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.dialogTitle}
        >
          <Grid item className={classes.dialogTitleContent}>
            <Typography className={classes.inline} variant="h6">
              Members
            </Typography>
          </Grid>
          <IconButton aria-label="close">
            <CloseIcon
              color="disabled"
              fontSize="medium"
              onClick={() => {
                setKeyWord("");
                handleCloseDialog();
              }}
            />
          </IconButton>
        </Grid>

        <TextField
          className={classes.contentSearch}
          variant="outlined"
          size="small"
          placeholder="Search"
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
        />
        <DialogContent className={classes.dialogContent}>
          <div className={classes.contentRows}>
            {sortedListMembers?.map((item) => {
              let userId = item._id || item.id;
              let isCurrentAdmin = adminIds?.includes(userId);

              let renderOptionButton = (handleOpen) =>
                isBroadcastChannel ? (
                  <IconButton
                    size="small"
                    color="primary"
                    className={clsx(classes.hiddenBtn, classes.optionBtn)}
                    disabled={sortedListMembers?.length <= 3}
                    onClick={() => {
                      setShowDelDialog(true);
                      setRemovedUser(item);
                    }}
                  >
                    <DeleteIcon style={{ fontSize: 20 }} />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    color="primary"
                    className={clsx(classes.hiddenBtn, classes.optionBtn)}
                    onClick={handleOpen}
                  >
                    <MoreIcon style={{ fontSize: 20 }} />
                  </IconButton>
                );

              if (currentUserId === userId)
                return (
                  <div
                    key={item._id || item.id}
                    className={clsx({
                      [classes.contentRow]: true,
                    })}
                  >
                    <Avatar alt="avatar" src={item?.photo?.url} />
                    <div className={classes.employeeInfor}>
                      <Typography className={classes.fullName}>
                        {item.fullName}
                      </Typography>
                      {isCurrentAdmin ? (
                        <Typography className={classes.roleLabel}>
                          Admin
                        </Typography>
                      ) : null}
                    </div>
                    {!isAdmin || adminIds?.length >= 2 ? (
                      <FruProActionDropdown
                        globalMenuItems={[
                          {
                            label: "Leave group",
                            onClick: () => {
                              setShowLeaveDialog(true);
                              setRemovedUser(item);
                            },
                          },
                        ]}
                        renderButton={renderOptionButton}
                      />
                    ) : null}
                  </div>
                );

              if (!isAdmin) {
                return (
                  <div
                    key={item._id || item.id}
                    className={clsx({
                      [classes.contentRow]: true,
                    })}
                  >
                    <Avatar alt="avatar" src={item?.photo?.url} />
                    <div className={classes.employeeInfor}>
                      <Typography className={classes.fullName}>
                        {item.fullName}
                      </Typography>
                      {isCurrentAdmin ? (
                        <Typography className={classes.roleLabel}>
                          Admin
                        </Typography>
                      ) : null}
                    </div>
                  </div>
                );
              }

              // if admin, show More option button
              let globalMenuItems = [];
              if (sortedListMembers?.length >= 2 && !isCurrentAdmin)
                globalMenuItems.push({
                  label: "Remove member",
                  onClick: () => {
                    setShowDelDialog(true);
                    setRemovedUser(item);
                  },
                });

              if (isCurrentAdmin && adminIds?.length > 1)
                globalMenuItems.push({
                  label: "Remove as admin",
                  onClick: () => {
                    let newAdminIds = adminIds.filter((id) => id !== userId);
                    let message = `removed ${item.fullName} as a group admin.`;
                    handleChangeAdmin(newAdminIds, message);
                  },
                });
              else
                globalMenuItems.push({
                  label: "Make admin",
                  onClick: () => {
                    let newAdminIds = [...adminIds, userId];
                    let message = `added ${item.fullName} as a group admin.`;
                    handleChangeAdmin(newAdminIds, message);
                  },
                });
              return (
                <div
                  key={item._id || item.id}
                  className={clsx({
                    [classes.contentRow]: true,
                  })}
                >
                  <Avatar alt="avatar" src={item?.photo?.url} />
                  <div className={classes.employeeInfor}>
                    <Typography className={classes.fullName}>
                      {item.fullName}
                    </Typography>
                    {isCurrentAdmin ? (
                      <Typography className={classes.roleLabel}>
                        Admin
                      </Typography>
                    ) : null}
                  </div>
                  <FruProActionDropdown
                    globalMenuItems={globalMenuItems}
                    renderButton={renderOptionButton}
                  />
                </div>
              );
            })}
          </div>
        </DialogContent>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <DialogActions>
          <FruProButton
            color="default"
            onClick={() => {
              setKeyWord("");
              handleCloseDialog();
            }}
          >
            Cancel
          </FruProButton>
        </DialogActions>
      </Dialog>
      <ConfirmRemoveUserDialog
        title="Remove from chat?"
        body={[
          "Are you sure that you want to remove this person from the conversation?",
          "They will no longer be able to send or receive new messages.",
        ]}
        open={showDelDialog}
        onClose={() => setShowDelDialog(false)}
        arrayButtons={[
          {
            onClick: () => setShowDelDialog(false),
            color: "default",
            text: "Cancel",
          },
          {
            onClick: () => {
              handleRemoveUserFromChannel(removedUser);
              setShowDelDialog(false);
            },
            color: "error",
            text: "Remove from chat",
          },
        ]}
      />
      <ConfirmRemoveUserDialog
        title="Leave group chat?"
        body={[
          "You will stop receiving messages from this conversation and people will see that you left.",
        ]}
        open={showLeaveDialog}
        onClose={() => setShowLeaveDialog(false)}
        arrayButtons={[
          {
            onClick: () => setShowLeaveDialog(false),
            color: "default",
            text: "Cancel",
          },
          {
            onClick: () => {
              handleRemoveUserFromChannel(removedUser);
              setShowLeaveDialog(false);
              handleCloseDialog();
            },
            color: "error",
            text: "Leave Group",
          },
        ]}
      />
    </>
  );
}

function ConfirmRemoveUserDialog({ arrayButtons, open, onClose, title, body }) {
  const classes = useStyles();

  return (
    <FruProDialog
      size="small"
      title={title}
      buttons={arrayButtons}
      open={open}
      titleBorder
      onClose={onClose}
      closeIcon
    >
      <Typography
        align="center"
        variant="body1"
        className={classes.deleteContent}
      >
        {body}
      </Typography>
    </FruProDialog>
  );
}
