import { createTheme } from "@material-ui/core/styles";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#E77228",
      primaryA: "#F49517",
      primaryB: "#FC932B",
    },
    secondary: {
      main: "#BEBEBE",
    },
    success: {
      main: "#2CCB8D",
    },
    error: {
      main: "#F6133D",
      errorA: "#FF4400",
    },
    default: {
      main: "#E8E8E8",
      text: "#151B22",
    },
    initial: {
      main: "#FFFFFF",
    },
    grey: {
      main: "#BEBEBE",
      light: "#F5F5F5",
      label: "#858688",
      text: "#8B8B8B",
    },
    yellow: {
      main: "#F4C327",
      light: "rgba(244,195,39,0.2)",
      dark: "#FFDE82",
    },
    blue: {
      main: "#04B0ED",
    },
    background: {
      main: "#f8f8f8",
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 14,
    // Add fonts for Mui component
    fontFamily: ["Montserrat", "sans-serif"].join(","),
    body1: {
      fontSize: 14,
    },
    subtitle1: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    body2: {
      color: "#858688",
      fontSize: "12px",
    },
  },
  overrides: {
    // MuiTextField: {
    //   root: {
    //     border: "none !important",
    //     outline: "none",
    //     boxShadow: "none !important",
    //     background: " #f7f7f7 !important",
    //     borderBottom: "1px solid #e8e8e8",
    //     fontSize: "1rem",
    //     padding: "10px 12px 5px 12px",
    //   },
    // },
    MuiInputBase: {
      root: {
        // padding: "10px 12px 5px 12px",
        background: " #f7f7f7",
      },
      inputMultiline: {
        padding: "10px 12px 5px 12px",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#858688",
        fontWeight: 500,
        fontSize: "16px",
      },
      formControl: {
        top: "-4px",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#E77228",
        height: "3px",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          background: "white !important",
        },
      },
    },
  },
});

export { muiTheme };
