import { Grid, TextField as MuiTextField } from "@material-ui/core";

export default function TextField({ 
  size,
  children,
  disableUnderline = true,
  ...props
}) {
  if (size) {
    let sizeProps = {
      xs: 12,
    };
    if (!size) {
      sizeProps.xs = true;
    } else {
      sizeProps.md = size * 2 > 8 ? 12 : size * 2 > 6 ? 6 : size * 2;
      sizeProps.lg = size;
    }
    return (
      <Grid item {...sizeProps}>
        <MuiTextField
          {...props}
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: {
              marginBottom: ".5rem",
            },
          }}
          InputProps={
            props.InputProps || {
              disableUnderline: disableUnderline,
            }
          }
          SelectProps={
            props.SelectProps || {
              displayEmpty: true,
            }
          }
        >
          {children}
        </MuiTextField>
      </Grid>
    );
  } else return <MuiTextField {...props}>{children}</MuiTextField>;
}
