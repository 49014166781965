import { axiosInstance } from "services";
import { firebase } from "./firebase";
import toastr from "toastr";
import { v4 as uuidv4 } from "uuid";
import { queryObjSerialize } from "@utils/index";
import { NOTIFY_MESSAGE_TYPE_ENUM } from "@constants";
import { setTotalUnreadNotify } from "./common";
import { USERS_IN_ROOM_TYPE } from "@containers/Message/constant";
import { getUTCFormat } from "../utils";
import { USER_INAPP_MESSAGE_SENT } from "./amptitude_contants";
import { AmptitudeEvents } from "./amptitude_events";
// const CollectionPrexfix = "";
const CollectionPrexfix =
  process.env.NODE_ENV === "development" ? "FE-dev-" : "";

let currentUserId = "";
let currentUser;

export function setCurrentUser(user) {
  currentUserId = user?.id.toString();
  currentUser = user;
}

export function getListChannels(userId, channelTypes) {
  let listChannels = [];
  let url = `${CollectionPrexfix}users/${userId}/channels`;
  let firestore = firebase.firestore;

  return new Promise((resolve, reject) => {
    firestore()
      .collection(url)
      .orderBy("latestUpdate", "desc")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          listChannels.push({ id: doc.id, ...doc.data() });
        });
        resolve(listChannels);
      })
      .catch((e) => {
        resolve([]);
      });
  });
}

export function getChannelsByIDs(userId, IDs) {
  let listChannels = [];
  let url = `${CollectionPrexfix}users/${userId}/channels`;
  let firestore = firebase.firestore;
  return new Promise((resolve, reject) => {
    firestore()
      .collection(url)
      .where(firestore.FieldPath.documentId(), "in", IDs)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          listChannels.push({ id: doc.id, ...doc.data() });
        });
        resolve(listChannels);
      })
      .catch((e) => {
        resolve([]);
      });
  });
}

export async function createChannel(
  currentUser,
  targetUser,
  isPending,
  isArray,
  channelTypes
) {
  let { isBroadcast } = channelTypes || {};
  let latestUpdate = Date.now();
  let currentUserId = currentUser?.id;
  if (!currentUserId) return;
  let channelData = {
    latestMessage: "",
    latestMediaMessage: "",
    latestUpdate: latestUpdate,
    unReadUsers: {
      [currentUserId]: 0,
    },
    channelName:
      targetUser?.fullName || targetUser?.newChatInfo?.fullName || null,
    channelPicture:
      targetUser?.photo?.url || targetUser?.newChatInfo?.photo?.url || null,
    productAttachment:
      targetUser?.productAttachment ||
      targetUser?.newChatInfo?.productAttachment ||
      null,
    members: [currentUserId],
    // new field
    latestSenderId: currentUserId,
    // same as members, but with object
    membersInfo: {
      [currentUserId]: {
        id: currentUserId || "",
        email: currentUser?.email || "",
        fullName: currentUser?.fullName || "",
        photo: currentUser?.photo || null,
        companyId: currentUser?.companyId || "",
        companySlug: currentUser?.companySlug || "",
        companyName: currentUser?.companyName || "",
      },
    },
    channelUUID: targetUser?.channelUUID || "",
    isGroupChat: false,
    isBroadcast: Boolean(isBroadcast),
    type: Boolean(isBroadcast) ? "BROADCAST" : "USER",
  };
  // if targetUser?.newChatInfo is array, create group chat
  if (isArray && Array.isArray(targetUser?.newChatInfo)) {
    targetUser.newChatInfo.map((user) => {
      channelData.unReadUsers[user?.id] = 0;
      channelData.members.push(user?.id);
      channelData.membersInfo[user?.id] = {
        id: user?.id || "",
        email: user?.email || "",
        fullName: user?.fullName || "",
        photo: user?.photo || null,
        companyId: user?.companyId || "",
        companySlug: user?.companySlug || "",
        companyName: user?.companyName || "",
      };
    });
    channelData.channelName = [currentUser, ...targetUser.newChatInfo]
      .map((user) => user?.fullName)
      .filter((i) => !!i)
      .join(", ");
    channelData.channelPicture = "";
    channelData.isGroupChat = true;
    // add adminIds
    channelData.adminIds = currentUserId ? [currentUserId] : [];
    channelData.type = isBroadcast ? "BROADCAST" : "GROUP";
  } else {
    // create 1-1 chat
    channelData.unReadUsers[targetUser?.id] = 0;
    channelData.members.push(targetUser?.id);
    channelData.membersInfo[targetUser?.id] = {
      id: targetUser?.id || "",
      email: targetUser?.email || "",
      fullName: targetUser?.fullName || "",
      photo: targetUser?.photo || null,
      companyId: targetUser?.companyId || "",
      companySlug: targetUser?.companySlug || "",
      companyName: targetUser?.companyName || "",
    };
  }
  if (isPending)
    return {
      ...channelData,
      isPending,
    };

  let firestore = firebase.firestore;
  const channelRef = await firestore()
    .collection(`${CollectionPrexfix}users/${currentUserId}/channels`)
    .add(channelData);

  if (!isBroadcast) {
    if (!isArray)
      await firestore()
        .collection(`${CollectionPrexfix}users/${targetUser?.id}/channels`)
        .doc(channelRef.id)
        .set({
          ...channelData,
          channelName: currentUser?.fullName,
        });
    else
      await Promise.all(
        targetUser.newChatInfo.map(async (targetUser) => {
          await firestore()
            .collection(`${CollectionPrexfix}users/${targetUser?.id}/channels`)
            .doc(channelRef.id)
            .set({
              ...channelData,
            });
        })
      );
  }
  //
  syncCreateChannel({ ...channelData, id: channelRef.id });
  return { ...channelData, id: channelRef.id };
}

export function updateChannel({
  userId,
  channelId,
  message,
  mediaMessage,
  createAt,
  productAttachment,
  members,
  membersInfo,
  unReadUsers,
  channelName,
  isRenamed,
  newAdminIds,
  isGroupChat,
  channelUUID,
  isBroadcast,
}) {
  let channelData = {
    latestMessage: message,
    latestMediaMessage: mediaMessage,
    latestUpdate: createAt,
    latestSenderId: currentUserId,
    [`unReadUsers.${userId}`]: 1,
    productAttachment: productAttachment || null,
    isBroadcast: Boolean(isBroadcast),
  };

  if (members) channelData.members = members;
  if (membersInfo) channelData.membersInfo = membersInfo;
  if (channelName) channelData.channelName = channelName;
  if (isRenamed) channelData.isRenamed = isRenamed;
  if (unReadUsers) {
    delete channelData[`unReadUsers.${userId}`];
    channelData.unReadUsers = unReadUsers;
  }
  if (newAdminIds) channelData.adminIds = newAdminIds;
  if (isGroupChat) channelData.isGroupChat = isGroupChat;
  if (channelUUID) channelData.channelUUID = channelUUID;

  let firestore = firebase.firestore;

  let url = `${CollectionPrexfix}users/${userId}/channels`;
  let channelRefs = firestore().collection(url).doc(channelId);
  return channelRefs
    .get()
    .then(function (doc) {
      if (!doc.exists) {
        channelRefs.set({
          ...channelData,
          channelName: channelData?.channelName || currentUser?.fullName,
          channelPicture: currentUser?.photo?.url || "",
        });
      } else {
        channelRefs.update({ ...channelData });
      }
    })
    .catch((error) => {
      console.log("Error getting document1:", error);
    });
}

export function deleteUserChannel(userId, channelId) {
  let firestore = firebase.firestore;

  let url = `${CollectionPrexfix}users/${userId}/channels`;
  let channelRefs = firestore().collection(url).doc(channelId);
  return channelRefs
    .delete()
    .then(function () {
      console.log("Document successfully deleted!");
    })
    .catch((error) => {
      console.log("Error getting document2:", error);
    });
}

export function listenChannels(userId, callBack) {
  let url = `${CollectionPrexfix}users/${userId}/channels`;
  let latestUpdate = Date.now();
  let firestore = firebase.firestore;
  return firestore()
    .collection(url)
    .where("latestUpdate", ">", latestUpdate)
    .onSnapshot(function (snapshot) {
      if (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          callBack({
            ...change.doc.data(),
            id: change.doc.id,
            type: change.type,
          });
        });
      }
    });
}

let headCursor = "";
let tailCursor = "";
const LIMIT = 10;

export function getMessageHistory(channelId) {
  let listMessage = [];
  let firestore = firebase.firestore;
  return new Promise((resolve, reject) => {
    firestore()
      .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
      .orderBy("createAt", "desc")
      .limit(LIMIT)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let message = { id: doc.id, ...doc.data() };
          listMessage.push(message);
        });
        if (listMessage.length > 0) {
          headCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
          tailCursor = querySnapshot.docs[0];
        }
        resolve(listMessage);
      });
  });
}

export function getMoreMessage(channelId, getNew) {
  let cursor = !getNew ? headCursor : tailCursor;
  let orderBy = !getNew ? "desc" : "asc";
  if (!cursor) {
    return new Promise((resolve) => resolve([]));
  }
  let listMessage = [];
  let firestore = firebase.firestore;
  return new Promise((resolve, reject) => {
    firestore()
      .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
      .orderBy("createAt", orderBy)
      .limit(LIMIT)
      .startAfter(cursor)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let message = { id: doc.id, ...doc.data() };
          if (!getNew) listMessage.push(message);
          else listMessage.unshift(message);
        });
        if (listMessage.length > 0) {
          if (!getNew)
            headCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
          else tailCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
        }
        resolve(listMessage);
      });
  });
}

export function getMoreMessageByMessageId(channelId, messageId) {
  let firestore = firebase.firestore;

  let messagesRef = firestore().collection(
    `${CollectionPrexfix}channels/${channelId}/messages`
  );

  return new Promise((resolve, reject) => {
    firestore()
      .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
      .doc(messageId)
      .get()
      .then(async (mainMessageDoc) => {
        let mainMessage = { id: mainMessageDoc.id, ...mainMessageDoc.data() };
        const getOneAfterMessage = new Promise((resolve, reject) => {
          messagesRef
            .orderBy("createAt", "asc")
            .startAfter(mainMessageDoc)
            .limit(1)
            .get()
            .then(function (querySnapshot) {
              let oneMsgAfterMessage = [];
              querySnapshot.forEach(function (doc) {
                let message = { id: doc.id, ...doc.data() };
                oneMsgAfterMessage.push(message);
              });
              tailCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
              resolve(oneMsgAfterMessage);
            });
        });
        const getEightBeforeMessage = new Promise((resolve, reject) => {
          messagesRef
            .orderBy("createAt", "desc")
            .startAfter(mainMessageDoc)
            .limit(8)
            .get()
            .then(function (querySnapshot) {
              let eightMsgBeforeMessage = [];
              querySnapshot.forEach(function (doc) {
                let message = { id: doc.id, ...doc.data() };
                eightMsgBeforeMessage.unshift(message);
              });
              if (eightMsgBeforeMessage.length > 0) {
                headCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
              }
              resolve(eightMsgBeforeMessage);
            });
        });
        let [eightBeforeMessage, oneAfterMessage] = await Promise.all([
          getEightBeforeMessage,
          getOneAfterMessage,
        ]);
        resolve([...eightBeforeMessage, mainMessage, ...oneAfterMessage]);
      });
  });
}

let messageListener = "";

// export const setTotalUnreadMessage = (channelId, userId, number) => {
//   let firestore = firebase.firestore;

//   let url = `${CollectionPrexfix}users/${userId}/channels`;
//   let channelRefs = firestore().collection(url).doc(channelId);

//   return channelRefs
//     .update({
//       totalUnread: firestore.FieldValue.increment(number),
//     })
//     .then(() => {
//       console.log("Document successfully updated!");
//     })
//     .catch((error) => {
//       // The document probably doesn't exist.
//       console.error("Error updating document: ", error);
//     });
// };

export function receiveMessage(channelId, callBack) {
  let firestore = firebase.firestore;
  let createAt = Date.now();
  // reset messageListener
  if (messageListener) {
    messageListener();
  }
  messageListener = firestore()
    .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
    .where("createAt", ">", createAt)
    .onSnapshot(function (snapshot) {
      if (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            callBack({ ...change.doc.data(), id: change.doc.id });
          }
        });
      }
    });
  return messageListener;
}

export const DOC_EXTENSIONS = ".xlsx,.xls,.pdf,.doc,.docx,.txt,.csv";

export function getFileType(file) {
  if (!file?.type) return "text";
  else if (file?.type.includes("image")) return "photo";
  else if (file?.type.includes("video")) return "video";
  else if (file?.type.includes("audio")) return "voice";
  else {
    const fileExtension = file?.name?.split(".").pop();
    let isDocument = DOC_EXTENSIONS.includes(fileExtension);
    if (isDocument) return "document";
    return "text";
  }
}

export function formatFirebaseMessage(
  message,
  files,
  createAtProp,
  messageUUID,
  productAttachment,
  repliedMessage
) {
  let createAt = createAtProp || Date.now();
  let messageData = {
    senderId: currentUser?.id,
    message: message,
    name: currentUser.fullName,
    type:
      files && files?.length > 0
        ? getFileType(files[0])
        : productAttachment?.id
        ? "product"
        : "text",
    productInfo: null,
    files: files || null,
    createAt: createAt,
    messageUUID: messageUUID || "",
    productAttachment: productAttachment || null,
    linkedMessageId: repliedMessage?.id || null,
    linkedMessage: repliedMessage?.message || null,
    linkedFiles: repliedMessage?.files || null,
    linkedProductAttachment: repliedMessage?.productAttachment || null,
  };
  return messageData;
}

export async function sendMessage(
  channelId,
  message,
  members,
  files,
  messageUUID,
  productAttachment,
  createAtProp,
  repliedMessage,
  isBroadcast = false
) {
  let createAt = createAtProp || Date.now();
  let messageData = formatFirebaseMessage(
    message,
    files,
    createAt,
    messageUUID,
    productAttachment,
    repliedMessage
  );

  if (files) {
    const listFile = await Promise.all(
      files.map(async (file) => {
        let fileRef = await uploadImage(file);
        const fileResult = {
          name: file.name,
          size: file.size,
          url: fileRef,
          type: file.type,
        };
        return fileResult;
      })
    );
    let createAt = Date.now();
    messageData = formatFirebaseMessage(
      message,
      listFile,
      createAt,
      messageUUID,
      undefined,
      repliedMessage
    );
  }
  let firestore = firebase.firestore;
  if (members) {
    let mediaMessage = "";
    if (messageData?.type !== "text") {
      switch (messageData?.type) {
        case "photo":
          mediaMessage = `sent ${
            files?.length === 1 ? "a photo" : `${files?.length} photos`
          }`;
          break;
        case "video":
          mediaMessage = `sent ${
            files?.length === 1 ? "a video" : `${files?.length} videos`
          }`;
          break;
        case "voice":
          mediaMessage = `sent a voice message`;
          break;
        case "document":
          mediaMessage = `sent ${
            files?.length === 1 ? "a document" : `${files?.length} documents`
          }`;
          break;
        case "product":
          mediaMessage = `sent product attachment`;
          break;
        default:
          break;
      }
    }
    members.forEach((item) => {
      if (isBroadcast && item === currentUserId) {
        updateChannel({
          userId: item,
          channelId,
          mediaMessage,
          message,
          createAt,
          productAttachment,
          unReadUsers: {
            [`${currentUserId}`]: 0,
          },
        });
      } else {
        updateChannel({
          userId: item,
          channelId,
          mediaMessage,
          message,
          createAt,
          productAttachment,
        });
      }
    });
  }

  if (isBroadcast) {
    return new Promise((resolve, reject) => {
      firestore()
        .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
        .add(messageData)
        .then(function (docRef) {
          console.log("Message sent with ID: ", docRef.id);
          if (messageData) {
            resolve({
              ...messageData,
              senderId: currentUser?.id,
              firebaseMessageId: docRef.id,
            });
          }
        });
    });
  }

  firestore()
    .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
    .add(messageData)
    .then(function (docRef) {
      console.log("Message sent with ID: ", docRef.id);
      if (messageData)
        syncSendMessage(channelId, {
          ...messageData,
          senderId: currentUser?.id,
          firebaseMessageId: docRef.id,
        });

      // updateTotalUnreadForUsers(members, channelId);
    });

  return await AmptitudeEvents("Message",currentUser?.id, USER_INAPP_MESSAGE_SENT);
}

export async function sendBroadcastMessage(
  channelId,
  message,
  members,
  files,
  messageUUID,
  productAttachment,
  createAtProp,
  repliedMessage
) {
  let createAt = createAtProp || Date.now();
  let messageData = formatFirebaseMessage(
    message,
    files,
    createAt,
    messageUUID,
    productAttachment,
    repliedMessage
  );

  if (files) {
    const listFile = await Promise.all(
      files.map(async (file) => {
        let fileRef = await uploadImage(file);
        const fileResult = {
          name: file.name,
          size: file.size,
          url: fileRef,
          type: file.type,
        };
        return fileResult;
      })
    );
    let createAt = Date.now();
    messageData = formatFirebaseMessage(
      message,
      listFile,
      createAt,
      messageUUID,
      undefined,
      repliedMessage
    );
  }
  let firestore = firebase.firestore;
  let mediaMessage = "";
  if (messageData?.type !== "text") {
    switch (messageData?.type) {
      case "photo":
        mediaMessage = `sent ${
          files?.length === 1 ? "a photo" : `${files?.length} photos`
        }`;
        break;
      case "video":
        mediaMessage = `sent ${
          files?.length === 1 ? "a video" : `${files?.length} videos`
        }`;
        break;
      case "voice":
        mediaMessage = `sent a voice message`;
        break;
      case "document":
        mediaMessage = `sent ${
          files?.length === 1 ? "a document" : `${files?.length} documents`
        }`;
        break;
      case "product":
        mediaMessage = `sent product attachment`;
        break;
      default:
        break;
    }
  }
  updateChannel({
    userId: currentUserId,
    channelId,
    mediaMessage,
    message,
    createAt,
    productAttachment,
    isBroadcast: true,
  });
  await AmptitudeEvents("Message",currentUser?.id, USER_INAPP_MESSAGE_SENT);

  return new Promise((resolve, reject) => {
    firestore()
      .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
      .add(messageData)
      .then(function (docRef) {
        console.log("Message sent with ID: ", docRef.id);
        resolve({
          ...messageData,
          id: docRef.id,
        });
      })
      .catch(() => {
        resolve({});
      });
  });
}

// function updateTotalUnreadForUsers(members, channelId) {
//   const roomMateIds = members?.filter((item) => item !== currentUserId);

//   roomMateIds.forEach((item) => {
//     setTotalUnreadMessage(channelId, item, 1);
//   });
// }

export function makeReadMessage(
  userId,
  channelId,
  members = [],
  messageData = {},
  isBroadcast = false
) {
  let userChannelsUrl = `${CollectionPrexfix}users/${userId}/channels`;
  let channelsUrl = `${CollectionPrexfix}channels`;

  let firestore = firebase.firestore;
  let userChannelRefs = firestore().collection(userChannelsUrl).doc(channelId);
  let channelRefs = firestore().collection(channelsUrl).doc(channelId);

  channelRefs
    .get()
    .then((doc) => {
      if (doc.exists) {
        const { usersInRoom } = doc.data();
        if (
          !usersInRoom?.includes(userId) &&
          messageData?.latestSenderId !== currentUserId
        ) {
          setTotalUnreadNotify(userId, -1);
        }
      }
    })
    .catch((error) => {
      console.log("Error", error);
    });

  userChannelRefs.update({
    [`unReadUsers.${userId}`]: 0,
    isIncrementBadge: false,
  });

  if (!isBroadcast) {
    members
      .filter((id) => id !== userId)
      .forEach((id) => updateLastVisit(id, channelId));
  }
}

export function updateLastVisit(userId, channelId) {
  let url = `${CollectionPrexfix}users/${userId}/channels`;
  let firestore = firebase.firestore;
  let lastVisit = Date.now();
  let channelRefs = firestore().collection(url).doc(channelId);
  channelRefs
    .update({
      lastVisit: lastVisit,
    })
    .catch((error) => {
      console.log("Error getting document3:", error);
    });
}

export function uploadImage(uploadedFile, uploadTimeStamp) {
  if (!currentUser?.id) {
    return toastr.error("Can not get current user");
  }
  const storage = firebase.storage;
  const storageRef = storage().ref();
  const timeStamp = new Date().getTime();
  const imageRef = storageRef.child(
    `attachments/${currentUser?.id}/${uploadTimeStamp || timeStamp}${
      uploadedFile.name
    }`
  );
  const metadata = {
    contentType: uploadedFile.type,
  };
  const file = new File([uploadedFile], uploadedFile.name);
  return imageRef
    .put(file, metadata)
    .then((snapshot) => snapshot.ref.getDownloadURL());
}

export function getFileNameByUrlFirebase(path) {
  try {
    const storage = firebase.storage;
    const httpsReference = storage().refFromURL(path);
    return httpsReference.name;
  } catch (error) {
    console.log("getFileNameByUrlFirebase error: ", error);
    return "";
  }
}

export async function syncGetListChannel(queryParams) {
  const queryStrings = queryObjSerialize(queryParams);
  try {
    const { data, status } = await axiosInstance.get(
      `channels?${queryStrings}`
    );
    if (status === 200) return data;
    return [];
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
}

export function addNewUserToChannel(
  channelId,
  newUsers,
  members,
  membersInfo,
  {
    isRenamed,
    channelName,
    adminIds,
    isGroupChat,
    type,
    channelUUID,
    isBroadcast,
  }
) {
  try {
    let createAt = Date.now();
    let messageData = {
      senderId: currentUser?.id || null,
      createAt: createAt,
      isSystemMessage: true,
      name: null,
      productAttachment: null,
      type: "text",
      files: null,
      messageUUID: uuidv4(),
    };

    let newMessage =
      newUsers.map((user) => user.fullName).join(", ") +
      " was added to group...";

    messageData.message = newMessage;
    let newUserIds = newUsers.map((user) => user.id || user._id);
    let newMembers = [...new Set([...members, ...newUserIds])];
    let newMembersInfo = {
      ...membersInfo,
    };
    newUsers.forEach(
      (newUser) =>
        (newMembersInfo[newUser.id || newUser._id] = {
          id: newUser.id || newUser._id || "",
          email: newUser?.email || "",
          fullName: newUser?.fullName || "",
          photo: newUser?.photo || null,
          companyId: newUser?.companyId || "",
          companySlug: newUser?.companySlug || "",
          companyName: newUser?.companyName || "",
        })
    );

    let firestore = firebase.firestore;
    if (newMembers) {
      if (isBroadcast) {
        newMembers
          .filter((item) => item === currentUserId)
          .forEach((item) => {
            let message = newMessage;
            let mediaMessage = "";
            let unReadUsers = newMembers.reduce(
              (obj, cur) => ({
                ...obj,
                [cur]: cur === item ? 1 : 0,
              }),
              {}
            );
            if (isRenamed)
              updateChannel({
                userId: item,
                channelId,
                mediaMessage,
                message,
                createAt,
                productAttachment: null,
                members: newMembers,
                membersInfo: newMembersInfo,
                unReadUsers: unReadUsers,
                channelName,
                isRenamed,
                newAdminIds: adminIds,
                isGroupChat,
                type,
                channelUUID,
                isBroadcast: true,
              });
            else {
              let channelName = Object.keys(newMembersInfo)
                .map((key) => newMembersInfo[key]?.fullName)
                .filter((i) => !!i)
                .join(", ");
              updateChannel({
                userId: item,
                channelId,
                mediaMessage,
                message,
                createAt,
                productAttachment: null,
                members: newMembers,
                membersInfo: newMembersInfo,
                unReadUsers: unReadUsers,
                channelName,
                newAdminIds: adminIds,
                isGroupChat,
                type,
                channelUUID,
                isBroadcast: true,
              });
            }
          });
      } else {
        newMembers.forEach((item) => {
          let message = newMessage;
          let mediaMessage = "";
          let unReadUsers = newMembers.reduce(
            (obj, cur) => ({
              ...obj,
              [cur]: cur === item ? 1 : 0,
            }),
            {}
          );
          if (isRenamed)
            updateChannel({
              userId: item,
              channelId,
              mediaMessage,
              message,
              createAt,
              productAttachment: null,
              members: newMembers,
              membersInfo: newMembersInfo,
              unReadUsers: unReadUsers,
              channelName,
              isRenamed,
              newAdminIds: adminIds,
              isGroupChat,
              type,
              channelUUID,
            });
          else {
            let channelName = Object.keys(newMembersInfo)
              .map((key) => newMembersInfo[key]?.fullName)
              .filter((i) => !!i)
              .join(", ");
            updateChannel({
              userId: item,
              channelId,
              mediaMessage,
              message,
              createAt,
              productAttachment: null,
              members: newMembers,
              membersInfo: newMembersInfo,
              unReadUsers: unReadUsers,
              channelName,
              newAdminIds: adminIds,
              isGroupChat,
              type,
              channelUUID,
            });
          }
        });
      }
    }

    syncUpdateMembers(newUserIds, channelId, true);

    return new Promise((resolve, reject) => {
      firestore()
        .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
        .add(messageData)
        .then(function (docRef) {
          console.log("Message sent with ID: ", docRef.id);
          resolve({
            isSuccess: true,
          });
        })
        .catch((erorr) => {
          resolve({
            isSuccess: false,
          });
        });
    });
  } catch (error) {
    console.error(error);
  }
}

export function setUsersInRoom(channelId, options) {
  const url = `${CollectionPrexfix}channels`;
  let firestore = firebase.firestore;
  const { type } = options;

  let channelRefs = firestore().collection(url).doc(channelId);

  return channelRefs
    .get()
    .then((doc) => {
      if (doc.exists) {
        channelRefs.update({
          usersInRoom:
            type === USERS_IN_ROOM_TYPE.ADD
              ? firestore.FieldValue.arrayUnion(currentUserId)
              : firestore.FieldValue.arrayRemove(currentUserId),
        });
      } else {
        channelRefs.set({
          usersInRoom: firestore.FieldValue.arrayUnion(currentUserId),
        });
      }
    })
    .catch((error) => {
      console.error("Error: ", error);
    });
}

export function removeUserFromChannel(
  channelId,
  removedUser,
  members,
  membersInfo,
  adminIds,
  isRenamed,
  isBroadcast
) {
  try {
    let createAt = Date.now();
    let messageData = {
      senderId: currentUser?.id || null,
      createAt: createAt,
      isSystemMessage: true,
      name: null,
      productAttachment: null,
      type: "text",
      files: null,
      messageUUID: uuidv4(),
    };

    let newMessage = `${currentUser?.fullName} removed ${removedUser?.fullName} from the group chat`;
    let removedUserId = removedUser.id || removedUser._id;
    if (removedUserId == currentUser?.id)
      newMessage = `${removedUser?.fullName} has left the group chat`;
    messageData.message = newMessage;

    // remove user data in members and membersInfo && adminIds
    let newMembers = [];

    if (isBroadcast) {
      newMembers = [currentUserId];
    } else {
      newMembers = [...new Set([...members])].filter(
        (memberId) => memberId !== removedUserId
      );
    }

    let newAdminIds = [...new Set([...adminIds])].filter(
      (memberId) => memberId !== removedUserId
    );

    let newMembersInfo = {
      ...membersInfo,
    };
    delete newMembersInfo[removedUserId];

    let firestore = firebase.firestore;
    if (newMembers)
      newMembers.forEach((item) => {
        let message = newMessage;
        let mediaMessage = "";
        let unReadUsers = newMembers.reduce(
          (obj, cur) => ({
            ...obj,
            [cur]: cur === item ? 1 : 0,
          }),
          {}
        );
        if (isRenamed)
          updateChannel({
            userId: item,
            channelId,
            mediaMessage,
            message,
            createAt,
            productAttachment: null,
            members: newMembers,
            membersInfo: newMembersInfo,
            unReadUsers: unReadUsers,
            newAdminIds,
            isBroadcast,
          });
        else {
          let channelName = Object.keys(newMembersInfo)
            .map((key) => newMembersInfo[key]?.fullName)
            .filter((i) => !!i)
            .join(", ");
          updateChannel({
            userId: item,
            channelId,
            mediaMessage,
            message,
            createAt,
            productAttachment: null,
            members: newMembers,
            membersInfo: newMembersInfo,
            unReadUsers: unReadUsers,
            channelName,
            newAdminIds,
            isBroadcast,
          });
        }
      });

    //clear firebase document of user
    deleteUserChannel(removedUserId, channelId);
    syncUpdateMembers([removedUserId], channelId);

    return firestore()
      .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
      .add(messageData)
      .then(function (docRef) {
        console.log("Message sent with ID: ", docRef.id);
      });
  } catch (error) {
    console.error(error);
  }
}

export function renameChannel(currentChannel, channelName, members) {
  try {
    let createAt = Date.now();
    let messageData = {
      senderId: currentUser?.id || null,
      createAt: createAt,
      isSystemMessage: true,
      name: null,
      productAttachment: null,
      type: "text",
      files: null,
      messageUUID: uuidv4(),
    };

    let newMessage = `Group name was changed to ${channelName} by ${currentUser?.fullName}`;

    messageData.message = newMessage;

    let firestore = firebase.firestore;

    if (currentChannel?.isBroadcast) {
      let message = newMessage;
      let mediaMessage = "";
      let unReadUsers = {
        [`${currentUserId}`]: 0,
      };

      updateChannel({
        userId: currentUserId,
        channelId: currentChannel?.id,
        mediaMessage,
        message,
        createAt,
        productAttachment: null,
        unReadUsers: unReadUsers,
        channelName,
        isRenamed: true,
        isBroadcast: true,
      });
    } else if (members)
      members.forEach((item) => {
        let message = newMessage;
        let mediaMessage = "";
        let unReadUsers = members.reduce(
          (obj, cur) => ({
            ...obj,
            [cur]: cur === item ? 1 : 0,
          }),
          {}
        );
        updateChannel({
          userId: item,
          channelId: currentChannel?.id,
          mediaMessage,
          message,
          createAt,
          productAttachment: null,
          unReadUsers: unReadUsers,
          channelName,
          isRenamed: true,
        });
      });

    syncRenameChannel(currentChannel?.id, channelName);

    return firestore()
      .collection(`${CollectionPrexfix}channels/${currentChannel?.id}/messages`)
      .add(messageData)
      .then(function (docRef) {
        console.log("Message sent with ID: ", docRef.id);
      });
  } catch (error) {
    console.error(error);
  }
}

export function updateChannelAdmins(
  channelId,
  newAdminIds = [],
  message,
  members
) {
  try {
    let createAt = Date.now();
    let messageData = {
      senderId: currentUser?.id || null,
      createAt: createAt,
      isSystemMessage: true,
      name: null,
      productAttachment: null,
      type: "text",
      files: null,
      messageUUID: uuidv4(),
    };

    let newMessage = `${currentUser?.fullName} ${message}`;

    messageData.message = newMessage;

    let firestore = firebase.firestore;
    if (members)
      members.forEach((item) => {
        let message = newMessage;
        let mediaMessage = "";
        let unReadUsers = members.reduce(
          (obj, cur) => ({
            ...obj,
            [cur]: cur === item ? 1 : 0,
          }),
          {}
        );
        updateChannel({
          userId: item,
          channelId,
          mediaMessage,
          message,
          createAt,
          productAttachment: null,
          unReadUsers: unReadUsers,
          newAdminIds,
        });
      });

    return firestore()
      .collection(`${CollectionPrexfix}channels/${channelId}/messages`)
      .add(messageData)
      .then(function (docRef) {
        console.log("Message sent with ID: ", docRef.id);
      });
  } catch (error) {
    console.error(error);
  }
}

export function syncCreateChannel(channelData) {
  let data = {
    firebaseChannelId: channelData.id,
    channelName: channelData.channelName,
    members: channelData.members,
    type: channelData.type,
  };
  delete data.id;
  return axiosInstance.post("/channels", data);
}

export function getMessageType(messageData) {
  let type = "";

  if (messageData?.productAttachment) {
    type = NOTIFY_MESSAGE_TYPE_ENUM.PRODUCT_ATTACHMENT;
  } else if (messageData?.message) {
    type = NOTIFY_MESSAGE_TYPE_ENUM.TEXT;
  } else if (messageData?.type === "photo") {
    type = NOTIFY_MESSAGE_TYPE_ENUM.PHOTO;
  } else if (messageData?.type === "video") {
    type = NOTIFY_MESSAGE_TYPE_ENUM.VIDEO;
  } else if (messageData?.type === "voice") {
    type = NOTIFY_MESSAGE_TYPE_ENUM.AUDIO;
  } else {
    type = NOTIFY_MESSAGE_TYPE_ENUM.FILE;
  }

  return type;
}

export function syncRenameChannel(firebaseChannelId, channelName) {
  return axiosInstance.put(`/channels/${firebaseChannelId}`, {
    channelName,
  });
}

export function syncSendMessage(firebaseChannelId, messageData) {
  const type = getMessageType(messageData);
  const body = {
    firebaseChannelId,
    type,
    message: null,
    firebaseMessageId: messageData.firebaseMessageId,
    senderId: messageData.senderId,
    createdAt: getUTCFormat(messageData?.createAt),
  };

  // Handle body data
  if (messageData?.files) {
    body.files = messageData?.files;
  } else if (messageData?.productAttachment) {
    const { id, name, priceIndication, priceGuide, category, company } =
      messageData.productAttachment;
    const { id: companyId, companyName, code, slug } = company;

    body.produce = {
      id,
      name,
      priceIndication,
      priceGuide,
      category,
      company: {
        id: companyId,
        companyName,
        code,
        slug,
      },
    };
  } else {
    body.message = messageData.message;
  }

  return axiosInstance.post(`/messages`, body);
}

export function syncSendBroadcastMessage(firebaseMessages, messageData) {
 
  const type = getMessageType(messageData);
  const body = {
    firebaseMessages,
    type,
    message: null,
    senderId: messageData.senderId,
    createdAt: getUTCFormat(messageData?.createAt),
  };

  // Handle body data
  if (messageData?.files) {
    body.files = messageData?.files;
  } else if (messageData?.productAttachment) {
    const { id, name, priceIndication, priceGuide, category, company } =
      messageData.productAttachment;
    const { id: companyId, companyName, code, slug } = company;

    body.produce = {
      id,
      name,
      priceIndication,
      priceGuide,
      category,
      company: {
        id: companyId,
        companyName,
        code,
        slug,
      },
    };
  } else {
    body.message = messageData.message;
  }

  return axiosInstance.post(`/broadcast-messages`, body);
}

export function syncUpdateMembers(members, firebaseChannelId, isAdd) {
  if (isAdd)
    return axiosInstance.post(`channels/${firebaseChannelId}/members`, {
      members,
    });
  else
    return axiosInstance.delete(`channels/${firebaseChannelId}/members`, {
      data: {
        members,
      },
    });
}

export const searchChannels = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(`/channels/search?${queryStrings}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const searchMessageInChannel = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(`/messages/search?${queryStrings}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export async function getChannels(queryParams) {
  try {
    const queryStrings = queryObjSerialize(queryParams);
    const { data, status } = await axiosInstance.get(
      `/channels?${queryStrings}`
    );
    if (status === 200) return data;
    return [];
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
}

export async function getMessageChannels(queryParams) {
  try {
    const queryStrings = queryObjSerialize(queryParams);
    const { data, status } = await axiosInstance.get(
      `/messages/search-channels?${queryStrings}`
    );
    if (status === 200) return data;
    return [];
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
}
