import { createSlice } from "@reduxjs/toolkit";
import { getUnreadNotifications } from "@services/notification";

export const slice = createSlice({
  name: "notification",
  initialState: {
    unreadCounts: 0
  },
  reducers: {
    setUnread: (state, action) => {
      state.unreadCounts = action.payload;
    },
  }
});

export const { setUnread } = slice.actions;

export const setUnreadNotificationsNumber = () => async (dispatch) => {
  try {
    const response = await getUnreadNotifications();
    dispatch(setUnread(response?.totalUnread || 0));
  } catch (error) {
    throw error;
  }
}

export default slice.reducer;
