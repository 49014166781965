import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  TextField,
  IconButton,
  Typography,
  Avatar,
  Box,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from "@material-ui/icons";
import { debounce } from "lodash";
import clsx from "clsx";
import { searchMessageInChannel } from "@services/chat";
import { getFullTime } from "@utils/index";
import { useStyles } from "./styles";
import { FruText } from "@components";

export default function SearchMessageContainer({
  open,
  initSearchMsg,
  handleClose,
  firebaseChannelId,
  onSelectedMessage,
}) {
  const classes = useStyles();
  const [keyword, setKeyWord] = useState("");
  const [matchedMessages, setMatchedMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setKeyWord(initSearchMsg || "");
    }
    setMatchedMessages([]);
  }, [open]);

  const handleInputChange = (e) => {
    const keyword = e.target.value;
    setKeyWord(keyword);
  };

  const fetchData = async (query, firebaseChannelId, callback) => {
    if (!query) callback([]);
    else {
      const response = await searchMessageInChannel({
        firebaseChannelId,
        keyword: query,
      });
      let matchedMessages = response?.items || [];
      matchedMessages = matchedMessages.map(
        ({ message, createdAt, sender, firebaseMessageId }) => {
          // includes message, sender's fullName, createdAt, photo
          let data = {
            message: message,
            firebaseMessageId: firebaseMessageId,
            createdAt: createdAt,
            fullName: sender?.fullName,
            imageUrl: sender?.photo?.thumbUrl,
          };

          return data;
        }
      );
      callback(matchedMessages);
    }
  };

  const debouncedFetchData = useCallback(
    debounce((query, firebaseChannelId, callback) => {
      fetchData(query, firebaseChannelId, callback);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedFetchData(keyword, firebaseChannelId, (res) => {
      setMatchedMessages(res);
    });
  }, [keyword]);

  const handleBackInitialChannels = () => {
    handleClose();
  };

  return (
    <>
      <Grid
        item
        className={clsx({
          [classes.popupHeader]: true,
          [classes.borderBottom]: true,
        })}
      >
        <div className={classes.center}>
          <Typography variant="h6" display="inline" className="fw-bold text-xl">
            Chats
          </Typography>
        </div>
      </Grid>
      <Grid container>
        <IconButton
          onClick={handleBackInitialChannels}
          className={classes.backButtonContainer}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
        <Grid item xs>
          <Box padding={2}>
            <TextField
              className={classes.contentSearch}
              variant="outlined"
              placeholder="Search in conversation"
              value={keyword}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" color="disabled" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" style={{ paddingRight: 16 }}>
                    {loading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        className={clsx({
          [classes.leftInfoContainer]: true,
          [classes.w100]: true,
        })}
      >
        {matchedMessages?.map((item, idx) => (
          <div
            key={idx}
            className={clsx({
              [classes.avatarContent]: true,
            })}
            onClick={() => {
              onSelectedMessage(firebaseChannelId, item?.firebaseMessageId);
            }}
          >
            <Avatar alt="avatar" src={item?.imageUrl} />
            <div className={classes.senderInfo}>
              <FruText
                fontSize="12px"
                fontWeight={500}
                className={classes.commonText}
              >
                {item.fullName}
              </FruText>
              <FruText
                fontSize="10px"
                fontWeight={500}
                className={classes.commonText}
              >
                {item.message}
              </FruText>
            </div>
            <FruText
              color="grey"
              fontSize="10px"
              className={classes.commonText}
              textAlign="end"
            >
              {getFullTime(item.createdAt)}
            </FruText>
          </div>
        ))}
      </Grid>
    </>
  );
}
