// use for styled-components -.-
export default {
  colors: {
    primary: "#E77228",
    success: "#2CCB8D",
  },
  texts: {
    primary: "#E77228",
    secondary: "#8B8B8B",
    black: "#151B22",
    grey: "#858688",
  },
  backgrounds: {
    grey: "#E8E8E8",
    grey2: "#f7f7f7",
  },
};
