import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import styled from "styled-components";
import { Dropdown, Tooltip } from "react-bootstrap";

import {
  COMPANY_STATUS_ENUM,
  DROPDOWN_MENU_ITEMS,
  MENU_ITEMS_RESTRICTION,
  SEARCH_TYPE_ENUM,
} from "@constants";
import Router from "next/router";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  Box,
  FormGroup,
  makeStyles,
  IconButton,
  Popover,
  Grid,
  Divider,
  Badge,
} from "@material-ui/core";
import { FruProButton, AsyncAutoComplete } from "@components";
import { useRouter } from "next/router";
import { setCurrentUser } from "@services/cart";
import cart, { getCart } from "@reducers/cart";
import { setUnreadNotificationsNumber } from "@reducers/notification";
import ListChannel from "@containers/Message/ListChannel";
import { setNewChatInfo, setGlobalChatTab } from "@reducers/chat";
import { getAutoCompleteProduces } from "@services/produce-explore";
import toastr from "toastr";
import OrdersMenu from "./OrdersMenu";
import { setAdvancedSearchParamsAction } from "@reducers/global";
import { CHAT_SIDE_BAR_KEYS } from "../../containers/Message/constant";
import { SHOW_AS_ENUM } from "@constants";
import { getAutocompleteItems } from "../../services/company-directory";

import Gleap from "gleap";

import { AmptitudeEvents } from "@services/amptitude_events";

import { MULTI_SEARCH } from "@services/amptitude_contants";
import CompanyNotRegisteredModal from "@components/CompanyNotRegisteredModal";

const Root = styled.header`
  // height: 64px;
  // box-shadow: 1px 1px 1px #dddfec;
  // padding: 0 140px;
  .login,
  .sign-up {
    text-decoration: none;
  }
  .login {
    color: #151b22;
    margin-right: 24px;
  }
  .sign-up {
    color: #fcfcfc;
    background: #e77228;
    padding: 6px 15px;
  }
  hr {
    height: 100%;
    background-color: #bebebe;
  }
`;
const RegistrationRoot = styled.header`
  // height: 64px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(11px);
  // box-shadow: 1px 1px 1px #dddfec;
  // padding: 0 140px;
  .login,
  .sign-up {
    text-decoration: none;
  }
  .login {
    color: #151b22;
    margin-right: 24px;
  }
  .sign-up {
    color: #fcfcfc;
    background: #e77228;
    padding: 6px 15px;
  }
  hr {
    height: 100%;
    background-color: #bebebe;
  }
`;
const DROPDOWN = styled(Dropdown)`
  padding: 0;

  .dropdown-menu.show {
    border-radius: 20px;
    border: none;
    width: 275px;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.05);
  }
  .dropdown-item:hover {
    // background: #EE9C68;
    background-color: rgba(238, 156, 104, 0.2) !important;
    // opacity: 0.2;
    margin-left: 7px;
    color: #e77228;
    width: auto;

    border-radius: 10px;
  }
  a {
    height: 40px;
    color: ${(props) => props.theme.texts.black};
    text-decoration: none;
    &:active {
      color: ${(props) => props.theme.texts.black};
      background: #f3f3f3;
    }
    .name {
      margin-left: 12px;
      font-weight: 500;
      font-size: 15px;
    }
  }
  &.show {
    button {
      background: #f3f3f3 !important;
    }
    z-index: 9999;
  }
  button {
    height: 48px;
    border: none;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    padding: 10px;
    color: ${(props) => props.theme.texts.black} !important;
    border-radius: 0;
    background: transparent !important;
    .avatar {
      width: 32px;
      height: 32px;
      background: url(${(props) =>
          props.avatarurl ? props.avatarurl : "/static/images/ic_avatar.png"})
        center center no-repeat;
      background-size: cover;
      display: inline-block;
      border-radius: 50%;
      margin-right: 10px;
    }
    .welcome {
      color: ${(props) => props.theme.texts.secondary};
      font-size: 12px;
    }
    .name {
      font-size: 13px;
      font-weight: 600;
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  headerWrapper: {},
  productTypeSelect: {
    background: `${theme.palette.initial.main} !important`,
    borderRadius: 18,
    width: 155,
    color: theme.palette.primary.main,
    fontSize: "0.9375rem",
    marginRight: "1rem",
    "& img": {
      display: "none",
    },
  },
  productTypeTextfield: {
    background: theme.palette.initial.main,
    color: theme.palette.grey.label,
    border: "1px solid #E8E8E8",
    width: 275,
    height: "50px !important",
    paddingBottom: "5px !important",
    borderRadius: 35,
    transition: "width 0.5s ease-in-out;",
    "&.expanded": {
      width: 350,
    },
  },
  paper: {
    transition: "width 0.5s ease-in-out;",
    "&.expanded": {
      width: 425,
    },
  },
  searchBtn: {
    textTransform: "initial",
    color: "white",
    borderRadius: "35px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center",
    width: "65px !important",
    height: "50px !important",
    background: "#69CDAE !important",
    margin: "0 !important",
    marginLeft: "22px !important",
  },
  headerItem: {
    padding: "11px !important",
    "& .redDot": {
      position: "absolute",
      display: "inline-block",
      width: 8,
      height: 8,
      borderRadius: 999,
      top: 6,
      right: 10,
    },
  },
  offerBtn: {
    minWidth: "unset !important",
    minHeight: "unset !important",
    padding: "4px 16px !important",
    margin: "0 27px 0 35px !important",
    fontSize: "15px !important",
    border: "1px solid white",
    borderRadius: "3px !important",
    fontWeight: "bold",
    textTransform: "inherit !important",
  },
  homeIcon: {
    "& path": {
      fill: "#E77228",
    },
  },
  tabs: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  tab: {
    fontSize: "13px",
    textTransform: "none",
    color: `${theme.palette.default.text} !important`,
    opacity: "1 !important",
    minWidth: "fit-content",
    paddingRight: "12px",

    "&.Mui-selected": {
      color: `${theme.palette.default.text} !important`,
    },
  },
  searchContainer: {
    padding: "5px 0",

    "& .MuiInput-root": {
      padding: "6px 12px 5px 12px",
    },
  },
  searchContainerWidth: {
    width: "calc(100% - 294px)",
  },
  logoContainer: {
    cursor: "pointer",
    width: "128px",
    minWidth: "128px",
  },
  mainPageLogoContainer: {
    marginTop: 7,
    cursor: "pointer",
    width: "128px",
    minWidth: "128px",
  },
  pt4: {
    paddingTop: "4px",
  },
  numberOfItem: {
    display: "inline-block",
    width: 18,
    height: 12,
    borderRadius: 7,
    background: theme.palette.error.main,
    fontSize: 8,
    fontWeight: 700,
    color: theme.palette.initial.main,
    textAlign: "center",
    position: "absolute",
    zIndex: "9999",
    top: 6,
    left: 25,
    "&.unreads": {
      left: 18,
    },
  },
  popup: {
    maxHeight: 520,
    width: 376,
  },
  channelContainer: { height: "initial" },
  viewAllMessageBtn: {
    margin: "0 !important",
    border: "none !important",
    width: "100% !important",
    padding: "16px !important",
  },
  headerSearch: {
    "@media screen and (max-width: 1440px)": {
      paddingTop: "4px",
    },
  },
  headerCenter: {
    background: theme.palette.primary.main,
  },
  headerCenterDashboard: {
    marginBottom: 30,
    background: theme.palette.primary.main,
  },
  headerBottom: {
    background: theme.palette.background.main,
    padding: "16px 0",
  },
  productTypes: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
  },
  donationButton: {
    backgroundColor: "#E77228",
    color: "white",
    border: "1px solid #FFFFFF",
    boxShadow: "0px 1px 2px rgba(51, 51, 51, 0.3)",
    borderRadius: "3px",
    width: "135px",
    height: "35px",
    alignItems: "center",
    cursor: "pointer",
    padding: "5px 0",
  },
  productType: {
    borderRadius: 20,
    border: "0.5px solid #69CDAE",
    backgroundColor: theme.palette.initial.main,
    boxShadow: "1px 1px 4px 0 rgba(51,51,51,0.3)",
    padding: "6px 0",
    cursor: "pointer",
  },
  searchTypeGroup: {
    flexDirection: "row",
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "white",
    },
    "& .MuiFormControlLabel-label": {
      color: "white",
      fontSize: 15,
      fontWeight: "bold",
      marginLeft: 6,
    },
    "& .MuiRadio-root": {
      color: "#BEBEBE",
      padding: 0,
    },
    "& svg": {
      width: 20,
      height: 20,
    },
    "& .MuiFormControlLabel-root": {
      marginRight: 8,
      marginLeft: 0,
    },
  },
  uploadImage: {},
  addIcon: {
    color: theme.palette.secondary.main,
  },
  typesContent: {
    display: "flex",
    justifyContent: "center",
  },
  //limited company modal

  modalIconsContainer: {
    width: "auto",
    overFlow: "auto",
    backgroundColor: "#92CBBA",
    height: "auto",
  },
  modalPhonesContainer: {
    width: "auto",
    height: "auto",
  },
  IconsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
    textAlign: "center",
  },
  ItemIcon: {
    margin: "20px 10px",
    alignItems: "center",
    alignContent: "center",
  },
  IconHeaderText: {
    marginLeft: 10,
    marginTop: 30,
    color: "#FFFFFF",
    fontSize: 21,
    textAlign: "center",
    fontWeight: 800,
  },
  IconText: {
    marginLeft: 10,
    marginTop: 30,
    marginBottom: 15,
    color: "#FFFFFF",
    fontSize: 15,
    textAlign: "center",
    fontWeight: 700,
  },
  phoneContainer: {
    display: "flex",
    gap: 30,
    justifyContent: "center",
    width: "auto",
    margin: "0 auto",
  },
  ButtonsContainer: {
    display: "flex",
    marginBottom: 20,
    flexWrap: "no-wrap",
    justifyContent: "center",
    gap: 10,
  },
  headerText: {
    marginTop: 5,
    fontWeight: 800,
    fontSize: 24,
    color: "white",
    textAlign: "center !important",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "flex-end",
    textAlign: "center",
    gap: 150,
  },
  phone: {
    height: 350,
  },
  limitedModal: {
    "@media (max-width: 1000px)": {
      display: "none !important",
    },
  },
  routeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
    margin: "0 50px",
    height: 60,
    color: "#7C7C7C",
    "& li": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      listStyle: "none",
      fontSize: 12,
      fontWeight: 600,
      cursor: "pointer",
      height: 60,
      minWidth: 80,
      "&:hover": {
        color: "#E77228",
      },
    },
  },
  selected: {
    borderBottom: "4px solid #E77228",
    color: "black",
  },
  defaultNoUserImage: {
    width: 35,
    height: 35,
    borderRadius: "100%",
    cursor: "pointer",
  },
  viewButton: {
    marginTop: 10,
    border: "1px solid #E77228",
    borderRadius: 20,
    display: "flex",
    fontWeight: 500,
    fontSize: 14,
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
    height: 35,
    color: "#E77228",
  },
  menuInfoBox: {
    padding: 15,
    margin: 15,
    fontSize: "16px",
    borderRadius: 10,
    background: "rgba(231, 114, 40, 0.10)",
  },
  menuImage: {
    width: 40,
    height: 40,
    borderRadius: "100%",
  },
  menuFullName: {
    fontWeight: 600,
    fontSize: 16,
  },
  menuCompanyName: {
    fontWeight: 400,
    fontSize: 12,
  },
}));

const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9 ]+$/;

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Please select Product
  </Tooltip>
);

const ENTER_KEY = "Enter";

function Header({ isPublic }) {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const company = useSelector((state) => state.auth.company);

  const productTypes = useSelector((state) => state.global.productTypes);
  const cartList = useSelector((state) => state.cart.cartList);
  const unreadNotifications = useSelector(
    (state) => state.notification.unreadCounts
  );
  const newMessageCount = useSelector((state) => state.chat.newMessageCount);
  const channels = useSelector((state) => state.chat.channels);
  const broadcastChannels = useSelector(
    (state) => state.chat.broadcastChannels
  );
  const advancedSearchParams = useSelector(
    (state) => state.global.advancedSearchParams
  );

  const isLimitedCompany = user?.companyStatus !== COMPANY_STATUS_ENUM.VERIFIED;

  const [subProductTypeIds, setProductTypeId] = useState(
    router.query?.productTypeIds?.split(",")?.length > 1
      ? "all"
      : router.query?.productTypeIds || "all"
  );

  const [keyword, setKeyword] = useState("");

  //const[option,setOption]=useState("")

  // Use for autocomplete produces
  const [autocompleteProducesQueryParams, setAutocompleteProducesQueryParams] =
    useState(null);
  const [autocompleteValue, setAutocompleteValue] = useState({
    keyword: "",
  });
  // Use for autocomplete company directory
  const [
    autocompleteCompanniesQueryParams,
    setAutocompleteCompaniesQueryParams,
  ] = useState(null);
  const [autocompleteCompanyValue, setAutocompleteCompanyValue] = useState({
    companyName: "",
  });

  const [openCompanyNotRegistered, setOpenCompanyNotRegistered] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);
  const [documentReferer, setDocumentReferer] = useState("");
  const [searchType, setSearchType] = useState(SEARCH_TYPE_ENUM.OFFER);
  const [chatActiveTab, setChatActiveTab] = useState(CHAT_SIDE_BAR_KEYS.CHAT);
  const [showAs, setShowAs] = useState(SHOW_AS_ENUM.DIRECTORY);
  // const [openLimitedCompanyModal, setOpenLimitedCompanyModal] = useState(false);

  const isDashboard = router.pathname === "/";
  const isSignUp = useMemo(
    () =>
      router.pathname === "/login" ||
      router.pathname === "/sign-up" ||
      router?.pathname === "/company-registration" ||
      router.pathname === "/forgot-password" ||
      router.pathname === "/subscribe"
  );

  useEffect(() => {
    setDocumentReferer(window.location.href);
  }, []);

  const multiSearchEvent = async () => {
    return await AmptitudeEvents(
      "Multi Search",
      user?.id,
      MULTI_SEARCH,
      documentReferer,
      user?.companyId,
      user?.companyName,
      keyword
    );
  };

  const isRestrictUser = useMemo(() => {
    return (
      !user?.companyId ||
      (user?.type === "COMPANY_ADMIN" &&
        user?.companyStatus === COMPANY_STATUS_ENUM.PENDING)
    );
  }, [user]);

  const handleClickMessageBtn = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMessage = () => {
    setAnchorEl(null);
  };

  const openMessage = Boolean(anchorEl);

  useEffect(() => {
    if ((router.query.name || router?.query?.keyword) !== keyword) {
      setKeyword(router.query?.name || router?.query?.keyword || "");
    }
    if (router.query?.productTypeIds !== subProductTypeIds) {
      if (
        router.query["productTypeIds[]"]?.length > 1 &&
        Array.isArray(router.query["productTypeIds[]"])
      ) {
        setProductTypeId("all");
      } else setProductTypeId(router.query["productTypeIds[]"] || "all");
    }

    if (!["/multi-search"].includes(router.pathname)) {
      keyword && setKeyword("");

      autocompleteProducesQueryParams &&
        setAutocompleteProducesQueryParams(null);
      autocompleteValue &&
        setAutocompleteValue({
          name: "",
        });
    }

    handleSetShowAs(router.pathname);
  }, [router.query]);

  useEffect(() => {
    if (user?.companyId) {
      setCurrentUser(user);
      dispatch(getCart(user));
    }
    if (user?.id && user?.isVerified) {
      dispatch(setUnreadNotificationsNumber());
    }
  }, []);

  //aşağıdaki useEffect her sayfa yenilenince multi-search e istek atıyor.
  useEffect(() => {
    setAutocompleteProducesQueryParams({
      ...autocompleteProducesQueryParams,
      productTypeIds:
        subProductTypeIds === "all" ? undefined : [subProductTypeIds],
    });
  }, [subProductTypeIds]);

  const clearAdvancedSearchParams = () => {
    if (Object.keys(advancedSearchParams).length) {
      dispatch(setAdvancedSearchParamsAction({}));
    }
  };

  const handleSetShowAs = (pathName) => {
    switch (pathName) {
      case "/produce-explore":
      case "/demanded-products":
        setShowAs(SHOW_AS_ENUM.PRODUCES);
        break;
      case "/company-directory":
        setShowAs(SHOW_AS_ENUM.DIRECTORY);
        break;
      case "/connection-explore":
        setShowAs(SHOW_AS_ENUM.USERS);
      default:
        break;
    }
  };

  const onSelectOption = (event, option) => {
    setAutocompleteProducesQueryParams({
      ...autocompleteProducesQueryParams,
      keyword: option.name,
    });

    if (showAs === SHOW_AS_ENUM.PRODUCES) {
      setAutocompleteValue(option);
    } else {
      setAutocompleteCompanyValue(option);
    }

    onSearch(option?.name);
  };

  const onSearch = (option) => {
    // TODO: should have a router handler for route constants

    if (isLimitedCompany) {
      // setOpenLimitedCompanyModal(true);
    } else {
      if (router.pathname.includes("/multi-search")) {
        router
          .replace({
            pathname: "/multi-search",

            query: {
              keyword: option ? option : keyword,
            },
            shallow: true,
          })
          .then((isSuccess) => {
            // force reload
            isSuccess && router.reload(router.asPath);
          });
      } else
        router.push({
          pathname: "/multi-search",
          query: {
            keyword: option ? option : keyword,
          },
        });

      clearAdvancedSearchParams();
    }
  };

  const onInputValueChange = (event, value) => {
    if (!ALPHA_NUMERIC_DASH_REGEX.test(value) && value) {
      toastr.error("Cannot allow special charecters");
      setKeyword("");
    } else {
      setKeyword(value);

      if (value?.length >= 3) {
        setAutocompleteProducesQueryParams({
          ...autocompleteProducesQueryParams,
          keyword: value,
        });
      }
    }
  };

  const handleChange = (val) => {
    setKeyword(autocompleteProducesQueryParams?.keyword);
    if (router.pathname.includes("/multi-search")) {
      router
        .replace({
          pathname: "/multi-search",

          query: {
            keyword: autocompleteProducesQueryParams?.keyword
              ? autocompleteProducesQueryParams?.keyword
              : keyword,
          },
          shallow: true,
        })
        .then((isSuccess) => {
          // force reload
          isSuccess && router.reload(router.asPath);
        });
    } else
      router.push({
        pathname: "/multi-search",
        query: {
          keyword: autocompleteProducesQueryParams?.keyword
            ? autocompleteProducesQueryParams?.keyword
            : keyword,
        },
      });
    clearAdvancedSearchParams();
  };

  const renderHeaderItems = () => {
    return HEADER_ITEMS.map((item, index) => {
      return (
        <IconButton
          className={classes.headerItem}
          key={index}
          href={user?.id ? item.href : ""}
          onClick={
            !user?.id
              ? () => router.push("/login")
              : user?.id && !user?.companyId
              ? () => setOpenCompanyNotRegistered(true)
              : item?.onClick
          }
        >
          <img
            style={{ width: 23.41, height: 19 }}
            src={item.count > 0 ? item.src : item.src2}
            alt={item.label}
          />

          {item.count > 0 && user?.id && (
            <Badge
              className="redDot"
              badgeContent={item.count}
              color="error"
              size="small"
            ></Badge>
          )}

          {/* {item.label} */}
        </IconButton>
      );
    });
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const renderSearchbar = () => {
    if (isPublic) return null;

    return (
      <Box width="100%" display="flex" alignItems="center">
        <Box paddingRight="1rem" style={{ cursor: "pointer" }}></Box>
        <FormGroup row style={{ alignItems: "center" }}>
          <Box display="flex" alignItems="center">
            {
              <>
                <AsyncAutoComplete
                  onFocus={() => setIsExpanded(true)}
                  onBlur={() => setIsExpanded(false)}
                  placeholder={`Search...`}
                  getData={
                    !isLimitedCompany && keyword !== ""
                      ? getAutocompleteItems
                      : getAutoCompleteProduces
                  }
                  value={!isLimitedCompany ? autocompleteValue : null}
                  inputValue={keyword}
                  onClick={onSearch}
                  queryParams={!isLimitedCompany ? keyword : null}
                  getOptionLabel={(option) =>
                    option?.name || option?.companyName || ""
                  }
                  handleChange={onSelectOption}
                  handleInputChange={onInputValueChange}
                  InputProps={{
                    startAdornment: (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <img
                            style={{ marginLeft: 10 }}
                            src="/static/svg/Search_light.svg"
                          />
                        </div>
                      </>
                    ),
                    disableUnderline: true,
                    classes: {
                      paper: classes.paper,
                      root: clsx(
                        classes.productTypeTextfield,
                        { expanded: isExpanded },
                        "fw-bold text-sm"
                      ),
                    },
                  }}
                  onKeyPress={(e) => {
                    //Press Shift with Enter

                    if (e.shiftKey && e.key === ENTER_KEY) {
                      return true;
                    }

                    if (e.key === ENTER_KEY) {
                      !user?.id
                        ? router.push("/login")
                        : user?.id && !user?.companyId
                        ? setOpenCompanyNotRegistered(true)
                        : multiSearchEvent();
                      onSearch(keyword);
                    }
                  }}
                ></AsyncAutoComplete>
              </>
            }
          </Box>
          <ul className={classes.routeContainer}>
            <li
              className={`${router.pathname === "/" ? classes.selected : ""}`}
              onClick={() => router.push("/")}
            >
              Latest
            </li>
            <li
              className={`${
                router.pathname === "/produce-explore" ? classes.selected : ""
              }`}
              onClick={() =>
                user?.id && !user?.companyId
                  ? setOpenCompanyNotRegistered(true)
                  : router.push("/produce-explore")
              }
            >
              Products
            </li>
            <li
              className={`${
                router.pathname === "/demanded-products" ? classes.selected : ""
              }`}
              onClick={() =>
                user?.id && !user?.companyId
                  ? setOpenCompanyNotRegistered(true)
                  : router.push("/demanded-products")
              }
            >
              Demands
            </li>
            <li
              className={`${
                router.pathname === "/my-company-profile"
                  ? classes.selected
                  : ""
              }`}
              onClick={() =>
                user?.id && !user?.companyId
                  ? setOpenCompanyNotRegistered(true)
                  : router.push("/my-company-profile")
              }
            >
              My Company
            </li>
          </ul>
          <div className={classes.headerIcon}>
            {user ? (
              <>
                <Popover
                  open={openMessage}
                  anchorEl={anchorEl}
                  onClose={handleCloseMessage}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Grid container className={classes.popup}>
                    <Grid item xs={12}>
                      <ListChannel
                        showHeader={false}
                        channels={
                          chatActiveTab === CHAT_SIDE_BAR_KEYS.CHAT
                            ? channels
                            : broadcastChannels
                        }
                        onClickChannel={(selectedChannel) => {
                          dispatch(setGlobalChatTab(chatActiveTab));
                          dispatch(setNewChatInfo(selectedChannel));
                          handleCloseMessage();
                        }}
                        userId={user?.id}
                        onClickNewMessage={() => null}
                        channelClassName={classes.channelContainer}
                        activeTab={chatActiveTab}
                        setActiveTab={setChatActiveTab}
                      />
                    </Grid>
                    {user?.companyStatus === "VERIFIED" ? (
                      <Grid item xs={12}>
                        <FruProButton
                          color="initial"
                          className={classes.viewAllMessageBtn}
                          onClick={() => {
                            dispatch(
                              setNewChatInfo({
                                onlyToggleChatPanel: true,
                              })
                            );
                            handleCloseMessage();
                          }}
                        >
                          View all message
                        </FruProButton>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Popover>
                <OrdersMenu
                  anchorEl={orderAnchorEl}
                  handleClose={() => {
                    setOrderAnchorEl(null);
                  }}
                />
              </>
            ) : null}
          </div>
        </FormGroup>
      </Box>
    );
  };

  const handleRedirectTo = (productTypeIds) => {
    router.push({
      pathname: "/produce-explore",
      query: {
        productTypeIds,
      },
    });
  };

  const renderHeaderNavigator = () => {
    let navigatorNames = [
      { route: "/", name: "Home" },
      {
        route: "/produce-explore",
        name: "Offers",
        navigate: true,
      },
      {
        route: "/demanded-products",
        name: "Demands",
        navigate: true,
      },
      {
        route: "/company-directory",
        name: "Company Directory",
        navigate: isLimitedCompany ? false : true,
      },
      {
        route: "/connection-explore",
        name: "Connections",
        navigate: isLimitedCompany ? false : true,
      },
      //{ route: "/my-products/my-product-listings", name: "Make a Donation" },
    ];

    return (
      <div className="container">
        <Box className={classes.productTypes}></Box>
      </div>
    );
  };

  
  const HEADER_ITEMS = [
    {
      src: "/static/svg/ic_notification1.svg",
      src2: "/static/svg/ic_notification1-gray.svg",
      label: "Notifications",
      href: "/notifications",
      ifHasSrc: "/static/svg/ic_cart.svg",
      count: unreadNotifications,
    },
    {
      src: "/static/svg/ic_new_message.svg",
      src2: "/static/svg/ic_new_message-gray.svg",
      label: "Message",
      href: "",
      ifHasSrc: "/static/svg/ic_cart.svg",
      onClick: handleClickMessageBtn,
      count: newMessageCount,
    },
  ];

  return isSignUp || router.pathname === "/mdu/[id]" ? (
    <RegistrationRoot>
      <div className="container pb-1 pt-1">
        <Box className={classes.headerWrapper}>
          <div className="d-flex p-0 justify-content-between">
            <Box className={clsx(classes.logoContainer, classes.pt4)}>
              <a href={isRestrictUser ? "/login" : "/"}>
                <img width="92" height="36" src="/static/images/logo.png" />
              </a>
            </Box>
          </div>
        </Box>
      </div>

      {window.location.pathname === "/produce-explore" ||
      window.location.pathname === "/connection-explore" ||
      window.location.pathname === "/company-directory" ||
      window.location.pathname === "/demanded-products" ? (
        <Box className={classes.headerBottom}>{renderHeaderNavigator()}</Box>
      ) : null}
    </RegistrationRoot>
  ) : (
    <>
      <Root>
        <div className="container">
          <Box className={classes.headerWrapper}>
            <div className="d-flex p-0 justify-content-between">
              <Box className={clsx(classes.mainPageLogoContainer, classes.pt4)}>
                <a href={isRestrictUser ? "/login" : "/"}>
                  <img width="92" height="36" src="/static/images/logo.png" />
                </a>
              </Box>
              <Box>{renderSearchbar()}</Box>

              {!isSignUp ? (
                <div className="d-flex align-items-center justify-content-end">
                  {
                    <Box className={classes.headerList}>
                      {renderHeaderItems()}
                    </Box>
                  }
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {!user?.id ? (
                    <img
                      onClick={() => router.push("/login")}
                      className={classes.defaultNoUserImage}
                      src="/static/images/ic_avatar.png"
                    />
                  ) : (
                    <DROPDOWN
                      avatarurl={ user?.photo?.url ||user?.photo?.thumbUrl }
                    >
                      <Dropdown.Toggle>
                        <div className="avatar" />
                        <div className="welcome-wrapper text-start">
                          {/* <div className="welcome">Welcome,</div>
                        <div className="name">
                          {user.firstName} {user.lastName}
                        </div> */}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu onClick={(e) => e.preventDefault()}>
                        <div className={classes.menuInfoBox}>
                          <div className="d-flex- flex-row gap-2 align-items-center">
                            <img
                              className={classes.menuImage}
                              src={user?.photo?.url}
                            />
                            <div className="d-flex flex-column">
                              <span className={classes.menuFullName}>
                                {user?.fullName}
                              </span>
                              <span className={classes.menuCompanyName}>
                                {company?.companyName}
                              </span>
                            </div>
                          </div>
                          <div
                            onClick={() =>
                              (window.location.href = "/my-user-profile")
                            }
                            className={classes.viewButton}
                          >
                            View Profile
                          </div>
                        </div>

                        {!isRestrictUser
                          ? DROPDOWN_MENU_ITEMS.map((m) => (
                              <Dropdown.Item
                                disabled={m.notClickable}
                                style={{ marginLeft: m.isChildren ? 20 : 0 }}
                                key={m.name}
                                href={m.href}
                                className="d-flex  flex-column"
                                onClick={(e) => {
                                  if (m?.trigger) {
                                    Gleap.open();
                                  } else {
                                    e.preventDefault();
                                    m.callback
                                      ? m.callback(dispatch)
                                      : Router.push(m.href);
                                  }
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  {m.isChildren ? (
                                    <div
                                      style={{
                                        width: 5,
                                        height: 5,
                                        background: "#E77228",
                                        borderRadius: "100%",
                                      }}
                                    ></div>
                                  ) : (
                                    <img width="22" height="22" src={m.src} />
                                  )}
                                  <span
                                    style={{ color: "#7C7C7C" }}
                                    className="name"
                                  >
                                    {m.name}
                                  </span>
                                </div>
                              </Dropdown.Item>
                            ))
                          : MENU_ITEMS_RESTRICTION.map((m) => (
                              <Dropdown.Item
                                key={m.name}
                                href={m.href}
                                className={`d-flex align-items-center ${
                                  !user?.companyId &&
                                  m.key !== "logout" &&
                                  "hide-on-pc"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  m.callback
                                    ? m.callback(dispatch)
                                    : Router.push(m.href);
                                }}
                              >
                                <img width="22" height="22" src={m.src} />
                                <span className="name">{m.name}</span>
                              </Dropdown.Item>
                            ))}
                      </Dropdown.Menu>
                    </DROPDOWN>
                  )}
                </div>
              ) : null}
            </div>
          </Box>
        </div>
        <Box
          className={
            isDashboard ? classes.headerCenterDashboard : classes.headerCenter
          }
        >
          <div className="container">
            {
              <Box
                display="flex"
                alignItems="center"
                className={clsx(
                  classes.searchContainer
                  // isDashboard && classes.searchContainerWidth
                )}
              ></Box>
            }
          </div>
        </Box>
        {window.location.pathname === "/produce-explore" ||
        window.location.pathname === "/connection-explore" ||
        window.location.pathname === "/company-directory" ||
        window.location.pathname === "/demanded-products" ? (
          <Box className={classes.headerBottom}>{renderHeaderNavigator()}</Box>
        ) : null}
      </Root>
      <CompanyNotRegisteredModal
        setOpenModal={setOpenCompanyNotRegistered}
        openModal={openCompanyNotRegistered}
      />
    </>
  );
}
export default Header;
