import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Typography,
  Grid,
  Link,
  Avatar,
  Tooltip,
  Box,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import {
  Group as GroupIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from "@material-ui/icons";
import { useStyles } from "./styles";
import { FruText } from "@components";
import { getChannels, getMessageChannels } from "@services/chat";
import { useTableFetch } from "@utils/customHook";
import { GROUP_ENUM } from "./constant";
import { LightTooltip } from "@components/Tooltip/Tooltip";

export default function InfiniteScrollChannels({
  channels,
  channel,
  onClickChannel,
  onClickNewMessage,
  showHeader = true,
  setInfiniteScrollScreen,
  infiniteScrollScreen,
  searchKeyword,
}) {
  const classes = useStyles();
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [searchedList, setSearchedList] = useState([]);

  const {
    data,
    setData,
    queryParams,
    setQueryParams,
    resetQueryParams,
    loading,
  } = useTableFetch({
    fetchData:
      infiniteScrollScreen.group === "channels"
        ? getChannels
        : getMessageChannels,
    isPaging: true,
    isSort: false,
    initialQueryParams: {
      keyword: searchKeyword,
    },
  });

  const handleBack = () => {
    setInfiniteScrollScreen({
      isShow: false,
      group: "",
    });
  };

  useEffect(() => {
    const list = document.querySelector(".list");
    list.addEventListener("scroll", (e) => {
      const element = e.target;
      if (element.scrollTop + element.clientHeight === element.scrollHeight) {
        setIsLoadMore(true);
      }
    });
  }, []);

  useEffect(() => {
    if (isLoadMore && queryParams.page < queryParams.totalPage) {
      setQueryParams({
        ...queryParams,
        page: queryParams.page + 1,
      });
      setIsLoadMore(false);
    }
  }, [isLoadMore]);

  useEffect(() => {
    let newSearchedChannels = [];

    if (infiniteScrollScreen.group === GROUP_ENUM.CHANNELS) {
      const channelIds = data.map((item) => item.firebaseChannelId);

      channels.forEach((channel) => {
        if (channelIds.includes(channel.id))
          newSearchedChannels.push({
            ...channel,
            isMatchedChannel: true,
          });
      });
    } else {
      const matchedMessageChannelIds = data.map(
        (item) => item.firebaseChannelId
      );

      channels.forEach((channel) => {
        if (matchedMessageChannelIds.includes(channel.id)) {
          newSearchedChannels.push({
            ...channel,
            isMatchedMessageChannel: true,
            matchedMessageCount: data.find(
              (m) => m.firebaseChannelId === channel.id
            )?.count,
          });
        }
      });
    }

    setSearchedList([...searchedList, ...newSearchedChannels]);
  }, [data]);

  const renderGroupAvatars = (item) => {
    let { members, membersInfo } = item;
    if (!members || !membersInfo)
      return (
        <Avatar alt="avatar">
          <GroupIcon />
        </Avatar>
      );
    let firstThreeAvatars = [];
    members.some((id) => {
      if (firstThreeAvatars.length === 3) return false;
      else {
        let imageUrl =
          membersInfo[id]?.photo?.thumbUrl || membersInfo[id]?.photo?.url;
        imageUrl && firstThreeAvatars.push(imageUrl);
      }
    });
    if (firstThreeAvatars.length === 0)
      return (
        <Avatar alt="avatar">
          <GroupIcon />
        </Avatar>
      );
    else if (firstThreeAvatars.length === 1)
      return <Avatar alt="avatar" src={firstThreeAvatars[0]} />;
    else if (firstThreeAvatars.length === 2)
      return (
        <Box
          display="flex"
          position="relative"
          width={40}
          height={40}
          minWidth={40}
          minHeight={40}
          marginRight="12px"
        >
          <Avatar
            alt="avatar-1"
            src={firstThreeAvatars[0]}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
          <Avatar
            alt="avatar-2"
            src={firstThreeAvatars[1]}
            sizes="26"
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
        </Box>
      );
    else if (firstThreeAvatars.length === 3)
      return (
        <Box
          display="flex"
          position="relative"
          width={40}
          height={40}
          minWidth={40}
          minHeight={40}
          marginRight="12px"
        >
          <Avatar
            alt="avatar-1"
            src={firstThreeAvatars[0]}
            style={{
              position: "absolute",
              right: 6,
              top: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
          <Avatar
            alt="avatar-2"
            src={firstThreeAvatars[1]}
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
          <Avatar
            alt="avatar-3"
            src={firstThreeAvatars[2]}
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
        </Box>
      );
  };

  const renderNoData = () => {
    return (
      <Box
        height={"100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontSize={13}
      >
        {loading ? (
          <Typography component="span" variant="body1">
            Loading...
          </Typography>
        ) : (
          "No data found"
        )}
      </Box>
    );
  };

  return (
    <>
      {showHeader ? (
        <>
          <Grid
            item
            className={clsx({
              [classes.popupHeader]: true,
              [classes.borderBottom]: true,
            })}
          >
            <Box className={classes.center}>
              <Typography
                variant="h6"
                display="inline"
                className="fw-bold text-xl"
              >
                Chats
              </Typography>
              <Link className="newMessage" onClick={onClickNewMessage}>
                New Message
              </Link>
            </Box>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={2}
              className={classes.backButtonContainer}
              style={{ background: "#FFFFFF" }}
            >
              <LightTooltip arrow title="Back" placement="top">
                <IconButton onClick={handleBack} style={{ padding: 8 }}>
                  <KeyboardBackspaceIcon />
                </IconButton>
              </LightTooltip>
            </Grid>
          </Grid>
        </>
      ) : null}
      <Grid
        className={clsx({
          [classes.leftInfoContainer]: true,
          list: true,
          [classes.w100]: true,
        })}
      >
        {searchedList.length
          ? searchedList.map((item, idx) => (
              <Grid
                key={idx}
                className={clsx({
                  [classes.avatarContent]: true,
                  [classes.selectedChannel]:
                    item?.channelUUID === channel?.channelUUID,
                  [classes.alignCenter]: true,
                })}
                onClick={() =>
                  onClickChannel(
                    item,
                    item?.isMatchedMessageChannel ? searchKeyword : ""
                  )
                }
              >
                {!item?.isGroupChat ? (
                  <Avatar alt="avatar" src={item.channelPicture} />
                ) : (
                  renderGroupAvatars(item)
                )}
                <Grid className={clsx(classes.center, "avatarInfo")}>
                  <Grid className="avatarName">
                    <Tooltip title={item.channelName} placement="top">
                      <div>
                        <FruText
                          fontSize="12px"
                          fontWeight={500}
                          className={classes.commonText}
                        >
                          {item.channelName}
                        </FruText>
                        {item?.isMatchedMessageChannel ? (
                          <FruText
                            fontSize="10px"
                            fontWeight={500}
                            className={classes.commonText}
                          >
                            {+item?.matchedMessageCount > 1
                              ? `${item.matchedMessageCount} matched message`
                              : "1 matched message"}
                          </FruText>
                        ) : null}
                      </div>
                    </Tooltip>
                    {item.productAttachment?.id ? (
                      <FruText
                        color="grey"
                        fontSize="10px"
                        className={classes.commonText}
                      >
                        [{item.productAttachment?.id}]
                      </FruText>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            ))
          : renderNoData()}
        {loading ? (
          <Grid container justifyContent="center">
            <CircularProgress color="primary" size={20} />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}
