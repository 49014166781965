import { axiosInstance } from "services";
import { queryObjSerialize } from "@utils/index";
import toastr from "toastr";
import axios from "axios";

export const createDeviceToken = async (token) => {
  try {
    const result = await axiosInstance.post(`/device-tokens`, {
      token: token,
      type: "WEB",
    });
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const getSetting = async () => {
  try {
    const result = await axiosInstance.get("/settings");
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getCompanySetting = async () => {
  try {
    const response = await axiosInstance.get("/settings-company");
    if (response) return response.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};
export const updateCompanySetting = async (data) => {
  try {
    const response = await axiosInstance.put("/setting-interests", data);
    if (response) {
      toastr.success("Interest settings updated successfully.");
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    return error;
  }
};

export const allWhatsNew = async () => {
  try {
    const response = await axiosInstance.get("/info/latestVersion");

    if (response.data) return response.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const updateSettings = async (body) => {
  try {
    const result = await axiosInstance.put("/setting-notify", body);
    if (result?.status === 204) {
      toastr.success(`Update settings successfully`);
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
    return error;
  }
};

export const getNotifications = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(`/notifications?${queryStrings}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const readNotification = async (notificationId) => {
  try {
    const result = await axiosInstance.put(`/notifications/${notificationId}`);
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const readAllNotification = async () => {
  try {
    const result = await axiosInstance.put("/notifications");
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const deleteAllNotification = async () => {
  try {
    const result = await axiosInstance.delete("/notifications");
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getUnreadNotifications = async () => {
  try {
    const result = await axiosInstance.get(`/total-unread-notifications`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const extendTimelineProduce = async ({
  companyId,
  produceId,
  extendDays,
}) => {
  try {
    const result = await axiosInstance.put(
      `/companies/${companyId}/produces/${produceId}/extend-days`,
      {
        extendDays,
      }
    );
    if (result?.status === 204) {
      return {
        isSuccess: true,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
    return error;
  }
};
