import React, { useEffect, useState, useCallback, useMemo } from "react";
import clsx from "clsx";
import {
  Typography,
  Grid,
  Link,
  InputAdornment,
  Avatar,
  Tooltip,
  Box,
  CircularProgress,
  IconButton,
  Button,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  Group as GroupIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from "@material-ui/icons";
import TextField from "@components/TextField";
import { LightTooltip } from "@components/Tooltip/Tooltip";
import { useStyles } from "./styles";
import { useRouter } from "next/router";
import { FruText } from "@components";
import { getFullTime, formatTime12, formatDate } from "@utils/index";
import { searchChannels } from "@services/chat";
import { debounce } from "lodash";
import {
  CHAT_SIDE_BAR_ITEMS,
  CHAT_SIDE_BAR_KEYS,
  GROUP_ENUM,
} from "./constant";
import ChatSideBar from "./ChatSideBar";
import { useSelector } from "react-redux";
import { COMPANY_STATUS_ENUM } from "@constants";

export default function ListChannel({
  channels,
  channel,
  userId,
  onClickChannel,
  onClickNewMessage,
  onClickNewBroadcast,
  showHeader = true,
  channelClassName,
  setInfiniteScrollScreen,
  searchKeyword,
  setSearchKeyword,
  handleClose,
  activeTab,
  setActiveTab,
  setCurrentChannel,
  broadcastChannelsRef,
  channelsRef,
}) {
  const classes = useStyles();
  const [searchedChannels, setSearchedChannels] = useState([]);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  // const [activeTab, setActiveTab] = useState(CHAT_SIDE_BAR_ITEMS[0].key);
  const [chatboxTitle, setChatboxTitle] = useState(
    CHAT_SIDE_BAR_ITEMS[0].label
  );
  const [newChatLabel, setNewChatLabel] = useState(
    CHAT_SIDE_BAR_ITEMS[0].newChatLabel
  );

  const globalChatTab = useSelector((state) => state.chat.globalChatTab);
  const user = useSelector((state) => state.auth.user);
  const isCompanyVerified =
    user?.companyStatus === COMPANY_STATUS_ENUM.VERIFIED;

  const renderLastMessage = ({
    latestMessage,
    latestSenderId,
    latestMediaMessage,
    channelName,
  }) => {
    if (latestMessage || !latestMediaMessage) return latestMessage;
    else if (latestSenderId === userId) return `You ${latestMediaMessage}`;
    return `${channelName} ${latestMediaMessage}`;
  };

  const handleInputChange = (e) => {
    const keyword = e.target.value;
    // setKeyWord(keyword);
    setSearchKeyword(keyword);
  };

  const handleBackInitialChannels = () => {
    setSearchKeyword("");
  };

  const handleSeeMore = (group) => {
    setInfiniteScrollScreen({
      isShow: true,
      group: group,
    });
  };

  const onMaximizeChat = () => {
    onClickChannel(channels[0]);
  };

  const fetchChannel = async (query, callback) => {
    setLoading(true);
    const response = await searchChannels({
      keyword: query,
    });

    callback(response);
    setLoading(false);
  };

  const debouncedFetchData = useCallback(
    debounce((query, callback) => {
      fetchChannel(query, callback);
    }, 500),
    []
  );

  const sortedChannels = useMemo(() => {
    let sortedChannels = [...channels];
    sortedChannels.sort((a, b) => b.latestUpdate - a.latestUpdate);
    return sortedChannels;
  }, [channels]);

  const getAddActionType = useCallback(() => {
    switch (activeTab) {
      case CHAT_SIDE_BAR_KEYS.CHAT:
        return () => onClickNewMessage();
      case CHAT_SIDE_BAR_KEYS.BROADCAST:
        return () => onClickNewBroadcast();
      default:
        break;
    }
  }, [activeTab]);

  const onClickMenuItem = (menuItem) => {
    setActiveTab(menuItem.key);
    setChatboxTitle(menuItem.label);
    setNewChatLabel(menuItem.newChatLabel);

    if (setCurrentChannel && channel) {
      if (menuItem.key === CHAT_SIDE_BAR_KEYS.CHAT) {
        setCurrentChannel(channelsRef.current[0]);
      } else {
        setCurrentChannel(broadcastChannelsRef.current[0]);
      }
    }
  };

  useEffect(() => {
    setSearchedChannels([]);
    if (searchKeyword) {
      debouncedFetchData(searchKeyword, (res) => {
        const channelIds = res.channels.map((item) => item.firebaseChannelId);
        const matchedMessageChannelIds = res.messages.map(
          (item) => item.firebaseChannelId
        );
        //TODO: group matched channels and messages
        let newSearchedChannels = [];
        channels.forEach((channel) => {
          if (channelIds.includes(channel.id))
            newSearchedChannels.push({
              ...channel,
              isMatchedChannel: true,
            });
        });
        channels.forEach((channel) => {
          if (matchedMessageChannelIds.includes(channel.id)) {
            newSearchedChannels.push({
              ...channel,
              isMatchedMessageChannel: true,
              matchedMessageCount: res.messages.find(
                (m) => m.firebaseChannelId === channel.id
              )?.count,
            });
          }
        });
        setSearchedChannels(newSearchedChannels);
      });
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (globalChatTab) setActiveTab(globalChatTab);
  }, [globalChatTab]);

  const renderGroupAvatars = (item) => {
    let { members, membersInfo } = item;
    if (!members || !membersInfo)
      return (
        <Avatar alt="avatar">
          <GroupIcon />
        </Avatar>
      );
    let firstThreeAvatars = [];
    members.some((id) => {
      if (firstThreeAvatars.length === 3) return false;
      else {
        let imageUrl =
          membersInfo[id]?.photo?.thumbUrl || membersInfo[id]?.photo?.url;
        imageUrl && firstThreeAvatars.push(imageUrl);
      }
    });
    if (firstThreeAvatars.length === 0)
      return (
        <Avatar alt="avatar">
          <GroupIcon />
        </Avatar>
      );
    else if (firstThreeAvatars.length === 1)
      return <Avatar alt="avatar" src={firstThreeAvatars[0]} />;
    else if (firstThreeAvatars.length === 2)
      return (
        <Box
          display="flex"
          position="relative"
          width={40}
          height={40}
          minWidth={40}
          minHeight={40}
          marginRight="12px"
        >
          <Avatar
            alt="avatar-1"
            src={firstThreeAvatars[0]}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
          <Avatar
            alt="avatar-2"
            src={firstThreeAvatars[1]}
            sizes="26"
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
        </Box>
      );
    else if (firstThreeAvatars.length === 3)
      return (
        <Box
          display="flex"
          position="relative"
          width={40}
          height={40}
          minWidth={40}
          minHeight={40}
          marginRight="12px"
        >
          <Avatar
            alt="avatar-1"
            src={firstThreeAvatars[0]}
            style={{
              position: "absolute",
              right: 6,
              top: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
          <Avatar
            alt="avatar-2"
            src={firstThreeAvatars[1]}
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
          <Avatar
            alt="avatar-3"
            src={firstThreeAvatars[2]}
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: 28,
              height: 28,
              margin: 0,
            }}
          />
        </Box>
      );
  };

  const renderNoData = () => {
    return (
      <Box
        height={"100%"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontSize={13}
      >
        {loading ? (
          <Typography component="span" variant="body1">
            Loading...
          </Typography>
        ) : (
          "No data found"
        )}
      </Box>
    );
  };

  const renderSearchedResults = () => {
    const resultGroups = [GROUP_ENUM.CHANNELS, GROUP_ENUM.CHAT];

    const matchedMessageChannels = searchedChannels?.filter(
      (channel) => channel.isMatchedMessageChannel
    );

    const matchedChannels = searchedChannels?.filter(
      (channel) => channel.isMatchedChannel
    );

    const result = {
      channels: matchedChannels,
      chat: matchedMessageChannels,
    };

    return (
      <Box className={classes.resultContainer}>
        {resultGroups.map((item, index) => (
          <Box className={classes.groupContainer}>
            <Box className={classes.groupHeader}>
              <Typography className={classes.searchedTitle} variant="body1">
                {item}
              </Typography>
              {result[item].length > 0 ? (
                <Button
                  onClick={() => {
                    handleSeeMore(item);
                  }}
                >
                  <Typography variant="body2" className={classes.seeMore}>
                    See more
                  </Typography>
                </Button>
              ) : null}
            </Box>
            <Grid className={loading ? classes.flexCenter : null}>
              {result[item].length
                ? result[item].map((item, idx) => (
                    <Grid
                      key={idx}
                      className={clsx({
                        [classes.avatarContent]: true,
                        [classes.selectedChannel]:
                          item?.channelUUID === channel?.channelUUID,
                        [classes.alignCenter]: true,
                      })}
                      onClick={() =>
                        onClickChannel(
                          item,
                          item?.isMatchedMessageChannel ? searchKeyword : ""
                        )
                      }
                    >
                      {!item?.isGroupChat ? (
                        <Avatar alt="avatar" src={item.channelPicture} />
                      ) : (
                        renderGroupAvatars(item)
                      )}
                      <Grid className={clsx(classes.center, "avatarInfo")}>
                        <Grid
                          className={`avatarName ${
                            item?.isBroadcast ? "broadcast" : null
                          }`}
                        >
                          <Tooltip title={item.channelName} placement="top">
                            <div>
                              <FruText
                                fontSize="12px"
                                fontWeight={500}
                                className={classes.commonText}
                              >
                                {item.channelName}
                              </FruText>
                              {item?.isMatchedMessageChannel ? (
                                <FruText
                                  fontSize="10px"
                                  fontWeight={500}
                                  className={classes.commonText}
                                >
                                  {+item?.matchedMessageCount > 1
                                    ? `${item.matchedMessageCount} matched message`
                                    : "1 matched message"}
                                </FruText>
                              ) : null}
                            </div>
                          </Tooltip>
                          {item.productAttachment?.id && item.isPending ? (
                            <FruText
                              color="grey"
                              fontSize="10px"
                              className={classes.commonText}
                            >
                              [{item.productAttachment?.id}]
                            </FruText>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))
                : renderNoData()}
            </Grid>
          </Box>
        ))}
      </Box>
    );
  };

  const renderAddNewMessageButton = useCallback(() => {
    let button = (
      <IconButton
        style={{ padding: 0 }}
        onClick={() => {
          onClickNewMessage(activeTab);
          if (searchKeyword) {
            setSearchKeyword("");
          }
        }}
      >
        <img src={`/static/svg/ic_new_message.svg`} alt="New message icon" />
      </IconButton>
    );

    switch (activeTab) {
      case CHAT_SIDE_BAR_KEYS.BROADCAST:
        button = (
          <IconButton
            style={{ padding: 0 }}
            onClick={() => {
              onClickNewBroadcast();
              if (searchKeyword) {
                setSearchKeyword("");
              }
            }}
          >
            <img
              src={`/static/svg/ic_new_broadcast.svg`}
              alt="New message icon"
            />
          </IconButton>
        );
        break;
      case CHAT_SIDE_BAR_KEYS.MUTE:
      case CHAT_SIDE_BAR_KEYS.ARCHIVED:
        button = null;
      default:
        break;
    }

    return button;
  }, [activeTab]);

  const renderChannelAvatar = (channel) => {
    if (channel?.isBroadcast) return null;

    return !channel?.isGroupChat ? (
      <Avatar alt="avatar" src={channel.channelPicture} />
    ) : channel?.isCompany ||
      channel?.companyId ||
      channel?.type === "COMPANY" ? (
      <Avatar alt="avatar" src={channel.companyLogo} />
    ) : (
      renderGroupAvatars(channel)
    );
  };

  const renderLastestUpdateDate = (channel) => {
    const lastestUpdate = channel?.latestUpdate;
    let formattedDate = formatDate(lastestUpdate);
    const fullTime = getFullTime(lastestUpdate);

    if (
      ["yesterday", "today"].includes(fullTime?.split(",")[0].toLowerCase())
    ) {
      formattedDate = fullTime?.split(",")[0];
    }

    return formattedDate;
  };

  const renderChannels = (channels) => {
    return (
      <Grid className={clsx(classes.leftInfoContainer, channelClassName)}>
        {channels?.length
          ? channels?.map((item, idx) => (
              <Grid
                key={idx}
                className={clsx({
                  [classes.avatarContent]: true,
                  [classes.selectedChannel]:
                    item?.channelUUID === channel?.channelUUID,
                })}
                onClick={() => onClickChannel(item)}
              >
                {renderChannelAvatar(item)}

                <Grid className={clsx(classes.center, "avatarInfo")}>
                  <Grid
                    className={`avatarName ${
                      item?.isBroadcast ? "broadcast" : null
                    }`}
                  >
                    <Tooltip title={item?.channelName} placement="top">
                      <div>
                        <FruText
                          fontSize="12px"
                          fontWeight={700}
                          className={classes.commonText}
                        >
                          {item?.channelName}
                        </FruText>
                      </div>
                    </Tooltip>
                    {item?.productAttachment?.id && item?.isPending ? (
                      <FruText
                        color="grey"
                        fontSize="10px"
                        className={classes.commonText}
                      >
                        [{item?.productAttachment?.id}]
                      </FruText>
                    ) : null}
                    <FruText
                      fontSize="12px"
                      fontWeight={500}
                      margin="8px 0 0"
                      color="grey"
                      className={classes.commonText}
                    >
                      {renderLastMessage(item)}
                    </FruText>
                  </Grid>
                  <Grid className="avatarDate">
                    <div>
                      <FruText
                        color="grey"
                        fontSize="12px"
                        className={classes.commonText}
                        textAlign="end"
                      >
                        {formatTime12(item?.latestUpdate)}
                      </FruText>
                      <FruText
                        color="grey"
                        fontSize="12px"
                        className={classes.commonText}
                        textAlign="end"
                      >
                        {renderLastestUpdateDate(item)}
                      </FruText>
                    </div>
                    {item?.unReadUsers?.[userId] ? (
                      <span className={classes.smsNumber} />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            ))
          : renderNoData()}
      </Grid>
    );
  };

  const renderChannelsWithSidebar = () => {
    let channelsRendered = [];

    switch (activeTab) {
      case CHAT_SIDE_BAR_KEYS.CHAT:
        channelsRendered = sortedChannels;
        break;
      case CHAT_SIDE_BAR_KEYS.BROADCAST:
        channelsRendered = sortedChannels;
        break;
      case CHAT_SIDE_BAR_KEYS.MUTE:
        channelsRendered = [];
        break;
      case CHAT_SIDE_BAR_KEYS.ARCHIVED:
        channelsRendered = [];
        break;
      default:
        break;
    }

    return (
      <Box display="flex">
        <ChatSideBar activeTab={activeTab} onClickMenuItem={onClickMenuItem} />
        {renderChannels(channelsRendered)}
      </Box>
    );
  };

  if (user.companyStatus === "VERIFIED") {
    return (
      <>
        {showHeader ? (
          <>
            <Grid
              item
              className={clsx({
                [classes.popupHeader]: true,
                [classes.borderBottom]: !!channel,
              })}
            >
              <div className={classes.center}>
                <Typography
                  variant="h6"
                  display="inline"
                  className="fw-bold text-xl"
                >
                  {chatboxTitle}
                </Typography>
                {!channel ? (
                  <Box>
                    <IconButton style={{ padding: 0 }} onClick={onMaximizeChat}>
                      <img
                        src={`/static/svg/ic_maximize.svg`}
                        alt="Maximize icon"
                      />
                    </IconButton>
                    <IconButton
                      style={{ padding: 0, marginLeft: 8 }}
                      onClick={handleClose}
                    >
                      <img src={`/static/svg/ic_close.svg`} alt="Close icon" />
                    </IconButton>
                  </Box>
                ) : null}
              </div>
              {!channel && isCompanyVerified && (
                <Link className="newMessage" onClick={getAddActionType()}>
                  {newChatLabel}
                </Link>
              )}
            </Grid>
            <Grid container>
              {searchKeyword ? (
                <LightTooltip arrow title="Back" placement="top">
                  <IconButton
                    className={classes.backButtonContainer}
                    onClick={handleBackInitialChannels}
                  >
                    <KeyboardBackspaceIcon />
                  </IconButton>
                </LightTooltip>
              ) : null}
              <Grid item xs>
                <Box
                  display="flex"
                  alignItems="center"
                  padding={2}
                  justifyContent="space-between"
                >
                  <TextField
                    className={clsx({
                      [classes.contentSearch]: true,
                      [classes.w100]: !channel || !isCompanyVerified,
                    })}
                    variant="outlined"
                    placeholder="Search"
                    value={searchKeyword}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" color="disabled" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ paddingRight: 16 }}
                        >
                          {loading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {channel && isCompanyVerified && renderAddNewMessageButton()}
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}
        {!searchKeyword ? renderChannelsWithSidebar() : renderSearchedResults()}
      </>
    );
  }
  return (
    <>
      {showHeader ? (
        <Grid
          item
          className={clsx({
            [classes.popupHeader]: true,
            [classes.borderBottom]: !!channel,
          })}
        >
          <div className={classes.center}>
            <Typography
              variant="h6"
              display="inline"
              className="fw-bold text-xl"
            >
              {chatboxTitle}
            </Typography>
            {!channel ? (
              <Box>
                <IconButton style={{ padding: 0 }} onClick={onMaximizeChat}>
                  <img
                    src={`/static/svg/ic_maximize.svg`}
                    alt="Maximize icon"
                  />
                </IconButton>
                <IconButton
                  style={{ padding: 0, marginLeft: 8 }}
                  onClick={handleClose}
                >
                  <img src={`/static/svg/ic_close.svg`} alt="Close icon" />
                </IconButton>
              </Box>
            ) : null}
          </div>
        </Grid>
      ) : null}
      <div
        style={{
          textAlign: "center",
          marginTop: 50,
          color: "#4D4D4E",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "406px",
          maxHeight: "100%",
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        <p>
          This is a <p style={{ color: "#69CDAE" }}>Premium account feature!</p>{" "}
          Upgrade your account to access this feature and{" "}
          <p style={{ color: "#69CDAE" }}>Many More!</p>
        </p>

        <p style={{ marginTop: 30 }}>
          Click to see packages and upgrade your account.
        </p>

        <button
          onClick={() =>
            router.push(`/subscribe?email=${user.email}`)
          }
          style={{
            marginBottom: 10,
            height: "46.74px",
            width: "146.66px",
            color: "white",
            backgroundColor: "#69CDAE",
            border: "1px solid #69CDAE",
            borderRadius: "10px",
          }}
        >
          UPGRADE
        </button>
      </div>
    </>
  );
}
