import { axiosInstance } from "services";
import toastr from "toastr";
import { queryObjSerialize } from "@utils/index";
import axios from "axios";

export const searchUserConnections = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(
      `/search-user-connections?${queryStrings}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const createConnections = async (connections) => {
  try {
    const response = await axiosInstance.post("/user-connections", {
      connections,
    });

    if (response?.status === 204) {
      toastr.success("You have just sent requests successfully!");

     
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error || error.response.data.message);

    return error;
  }
};

export const getConnections = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(`user-connections?${queryStrings}&limit=999`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const resendConnection = async (userId, message) => {
  try {
    const response = await axiosInstance.put(
      `/user-connections/${userId}/resend`,
      {
        message,
      }
    );
    if (response?.status === 204) {
      toastr.success("You have just resent requests successfully!");
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const acceptConnection = async (userId) => {
  try {
    const response = await axiosInstance.put(
      `/user-connections/${userId}/accept`
    );

    if (response?.status === 204 || amityFollow) {
      toastr.success("You have just accepted the request successfully!");
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const deleteConnection = async (userId) => {
  try {
    const response = await axiosInstance.delete(`/user-connections/${userId}`);

    if (response?.status === 204) {
      toastr.success("You have just deleted the connection successfully!");
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const setPremiumConnection = async (userId, isPremium) => {
  try {
    const response = await axiosInstance.put(
      `/user-connections/${userId}/set-premium`,
      {
        isPremium,
      }
    );
    if (response?.status === 204) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const getTotalConnections = async () => {
  try {
    const result = await axiosInstance.get("/total-user-connections");
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const getPremiumUsers = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(`/premium-users?${queryStrings}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const checkPremiumUsers = async (userId) => {
  try {
    const result = await axiosInstance.get(`/check-premium-users/${userId}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};
