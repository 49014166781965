import { queryObjSerialize, sanitizeEmptyArray } from "@utils/index";
import { axiosInstance } from "services";
import toastr from "toastr";

export const getCompanyDirectories = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(
      `/company-directories?${queryStrings}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getAutocompleteCompanyDirectory = async (queryParams) => {
  try {
    const result = await axiosInstance.post(
      "/autocomplete-company-directories",
      sanitizeEmptyArray(queryParams)
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getAutocompleteItems = async (keyword) => {
  try {
    const result = await axiosInstance.post(`/multi-search`, {
      keyword: keyword ? keyword : "",
      limit: 3,
    });
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getDetailCompanyDirectory = async (companyId) => {
  try {
    const { data, status } = await axiosInstance.get(
      `/company-directories/${companyId}`
    );
    if (status === 200) return data;
    return null;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
