import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  InputAdornment,
  TextField,
  IconButton,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { FruProButton } from "@components";
import { debounce } from "lodash";
import clsx from "clsx";
import { getListProduces } from "@services/produce-explore";
import ShareProductDialogItem from "./ShareProductDialogItem";
import { useSelector } from "react-redux";
import { getProduces } from "@services/my-store";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    position: "relative",
    padding: "20px 0 24px",
    "& button": {
      padding: 0,
      position: "absolute",
      top: 28,
      right: 34,
    },
  },
  dialogContent: {
    width: 740,
    height: 450,
    padding: 0,
    paddingLeft: 16,
  },
  contentRows: {
    height: "100%",
    "& p": {
      margin: 0,
    },
    "& .MuiCheckbox-root": {
      height: "fit-content",
      padding: 16,
      "& svg": {
        width: 20,
        height: 20,
      },
    },
  },
  contentSearch: {
    width: "100%",
    padding: "0 16px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& > .MuiInputBase-root": {
      height: 46,
      borderRadius: 10,
      background: "#E8E8E8",
      color: "#151B22",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 16,
    },
    marginBottom: 16,
  },
  dialogTitle: {
    padding: "16px",
  },
  resetBtn: {
    padding: 0,
    minWidth: "unset",
  },
  button: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    borderRadius: "3px !important",
    minWidth: "153px !important",
    maxHeight: 44,
  },
  cancelButton: {
    background: "#ffffff",
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    marginRight: 8,
  },
}));

export default function ShareProductDialog({
  open,
  handleClose,
  onSubmit,
  selecteds,
  onChecked,
}) {
  const classes = useStyles();
  const [keyword, setKeyWord] = useState("");
  const [produces, setProduces] = useState([]);
  const [loadingFetchProduces, setLoadingFetchProduces] = useState(false);

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!open) {
      setKeyWord("");
    }
  }, [open]);

  const handleInputChange = (e) => {
    const keyword = e.target.value;
    setKeyWord(keyword);
  };

  const fetchData = async (query, callback) => {
    setLoadingFetchProduces(true);
    let queryParams = {
      name: query,
      status: "Available",
      companyId: user?.companyId,
    };

    const response = await getProduces(queryParams);

    const produces = response?.items;
    callback(produces);
    setLoadingFetchProduces(false);
  };

  const debouncedFetchData = useCallback(
    debounce((query, callback) => {
      fetchData(query, callback);
    }, 500),
    []
  );

  useEffect(() => {
    if (open) {
      debouncedFetchData(keyword, (res) => {
        setProduces(res);
      });
    }
  }, [keyword, open]);

  const onResetSearchField = () => {
    setKeyWord("");
  };

  const renderData = () => {
    if (!produces?.length) {
      return (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>No items found</Typography>
        </Box>
      );
    }

    return produces?.map((produce, index) => (
      <React.Fragment key={produce?.id}>
        <ShareProductDialogItem
          selectedProduces={selecteds}
          onChecked={onChecked}
          produce={produce}
        />
      </React.Fragment>
    ));
  };

  const renderLoading = () => (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="primary" size={20} />
    </Box>
  );

  return (
    <>
      <Dialog
        className={classes.root}
        open={open}
        onClose={handleClose}
        maxWidth="lg"
      >
        <Box className={classes.dialogHeader}>
          <Typography variant="h6" align="center">
            Select a Product Listing
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <img
              src={`/static/svg/ic_close_dialog.svg`}
              alt="Close dialog icon"
            />
          </IconButton>
        </Box>
        <TextField
          className={classes.contentSearch}
          variant="outlined"
          size="small"
          placeholder="Search for Product Listings..."
          value={keyword}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={`/static/svg/ic_search_connections.svg`}
                  alt="Search connections icon"
                />
              </InputAdornment>
            ),
            endAdornment: (
              <Button
                className={clsx(classes.resetBtn, "fw-medium text-xs")}
                onClick={onResetSearchField}
              >
                Reset
              </Button>
            ),
          }}
        />
        <DialogContent className={classes.dialogContent}>
          <div className={classes.contentRows}>
            {loadingFetchProduces ? renderLoading() : renderData()}
          </div>
        </DialogContent>
        <hr style={{ marginTop: 0, marginBottom: 0 }} />
        <DialogActions>
          <Button
            className={clsx(classes.button, classes.cancelButton)}
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <FruProButton
            className={clsx(classes.button)}
            color="primary"
            onClick={onSubmit}
            disabled={!selecteds?.length}
          >
            Send listing
          </FruProButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
