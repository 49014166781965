import React from "react";
import { Box, Checkbox, Avatar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { getSubProductTypeDefaultImages, getThumbImageUrl } from "@utils/index";

const useStyles = makeStyles((theme) => ({
  contentRow: {
    display: "flex",
    padding: "16px 0",
    alignItems: "center",
    paddingRight: 32,
    borderBottom: "1px solid #E8E8E8",
    "&:hover": {
      cursor: "pointer",
      // backgroundColor: theme.palette.grey.light,
    },
  },
  rowChecked: {
    background: "#FAF0ED",
  },
  userAvatar: {
    width: 46,
    height: 46,
    objectFit: "cover",
    borderRadius: 2,
  },
  textGrey: {
    color: theme.palette.grey.label,
    fontWeight: 500,
  },
  textDarkGrey: {
    color: "#49494A",
  },
}));

export default function ContactDialogItem({
  selectedProduces,
  produce,
  onChecked,
}) {
  const classes = useStyles();
  const productTypes = useSelector((state) => state.global.productTypes);

  const renderPrice = () => {
    if (!produce?.priceGuide) {
      return (
        <Typography className="fw-medium text-sm" color="primary">
          Price on Request
        </Typography>
      );
    }

    return (
      <Typography className={clsx(classes.textGrey, "text-sm")}>
        {produce?.isFixedPrice ? "Fixed price:" : "Price guide:"}
        <Typography
          className="text-sm fw-medium"
          color="primary"
          component="span"
          style={{
            paddingLeft: 5,
          }}
        >
          {produce?.currency?.symbol + produce?.priceGuide}
        </Typography>
        <Typography
          className="text-sm fw-medium"
          component="span"
          style={{
            color: "#151B22",
          }}
        >
          {" "}
          / {produce?.unit?.name}
        </Typography>
      </Typography>
    );
  };

  return (
    <Box
      className={clsx({
        [classes.contentRow]: true,
        [classes.rowChecked]: selectedProduces
          ?.map((item) => item.id)
          .includes(produce?.id),
      })}
      onClick={() => onChecked(produce)}
    >
      <Box display="flex">
        <Checkbox
          checked={selectedProduces
            ?.map((item) => item.id)
            .includes(produce?.id)}
          color="primary"
        />
        <Avatar
          src={
            getThumbImageUrl(produce) ||
            getSubProductTypeDefaultImages(produce, productTypes)[0]?.thumbUrl
          }
          alt="user avatar"
          className={classes.userAvatar}
          variant="square"
        />
      </Box>

      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft="12px"
      >
        <Box className={classes.produceInfo}>
          <Typography className="text-sm fw-medium">{produce?.name}</Typography>
          <Typography className={classes.textGrey}>
            Brand:{" "}
            <span className={classes.textDarkGrey}>
              {produce?.brand || "N/A"}
            </span>
          </Typography>
          <Typography className={classes.textGrey}>
            Variety:{" "}
            <span className={classes.textDarkGrey}>
              {produce?.variety || "N/A"}
            </span>
          </Typography>
          <Typography className={classes.textGrey}>
            Category:{" "}
            <span className={classes.textDarkGrey}>
              {produce?.category || "N/A"}
            </span>
          </Typography>
        </Box>
        <Box>{renderPrice()}</Box>
      </Box>
    </Box>
  );
}
