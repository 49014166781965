import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  popoverChat: {
    "& .MuiPopover-paper": {
      borderRadius: 0,
    },
  },
  messageBtn: {
    borderRadius: 6,
    textTransform: "capitalize",
    maxHeight: 36,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: 15,
  },
  maximizeScreen: {
    "& .MuiPopover-paper": {
      width: 1200,
    },
  },
  messageContainer: {
    position: "fixed",
    bottom: 0,
    right: "calc(50% - 600px)",
    zIndex: 100,
  },
  absolute: {
    position: "absolute !important",
    bottom: 54,
  },
  pd12: {
    padding: "12px",
    paddingBottom: 0,
  },
  systemText: {
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
  },
  smsNumber: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "#F6133D",
    fontSize: 8,
    marginLeft: 8,
    color: "white",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    margin: "0 10px",
    fontWeight: "bold",
  },
  popup: {
    height: 680,
    // maxWidth: 1283,
    minWidth: 401,
    backgroundColor: "#fff",
  },
  center: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  popupHeader: {
    padding: 16,
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    height: 64,
    // borderBottom: `1px solid ${theme.palette.default.main}`,

    "& .newMessage": {
      fontSize: 12,
      fontWeight: 700,
      cursor: "pointer",
      textDecoration: "underline",
    },

    "& .iconClose": {
      height: "40px",
      width: "40px",
    },
  },
  channelName: {
    display: "flex",
    "&:hover $editBtn": {
      visibility: "visible",
    },
    alignItems: "center",
  },
  editBtn: {},
  hiddenBtn: {
    visibility: "hidden",
    marginLeft: 4,
  },
  sendTo: {
    fontWeight: 500,
  },
  contentSearch: {
    width: "85%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      height: "40px",
      padding: "0",
      paddingLeft: "14px",
      background: "#F3F3F3",
      borderRadius: 18,
      fontWeight: 700,
      fontSize: 15,
    },
  },
  commonText: {
    wordBreak: "break-word",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "700px",
  },
  avatarContent: {
    display: "flex",
    alignItems: "flex-start",
    padding: "12px 16px",
    borderBottom: `1px solid ${theme.palette.default.main}`,
    height: 60,
    "& .MuiAvatar-root": {
      marginRight: "8px",
    },

    "& .avatarName": {
      maxWidth: 178,
    },

    "& .broadcast": {
      maxWidth: 220,
    },

    "& .avatarInfo": {
      width: "100%",
      alignItems: "center",
    },

    "& .avatarDate": {
      textAlign: "end",
      display: "flex",
      alignItems: "center",
    },

    "&:hover": {
      backgroundColor: "#FCEBE1",
      cursor: "pointer",
    },
  },
  selectedChannel: {
    backgroundColor: "#FCEBE1",
  },
  leftInfoContainer: {
    overflowY: "auto",
    height: 540,
    width: "83%",

    /* Works on firefox */
    scrollbarWidth: "thin",
    scrollbarColor: "#D8D8D8",

    /* Works on Chrome, Edge, and Safari */
    "&::-webkit-scrollbar": {
      width: "4px",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D8D8D8",
      borderRadius: 20,
    },
  },
  rightInfoContent: {
    backgroundColor: "#F5F2F1",
    height: 470,
    overflowY: "auto",
    paddingBottom: 16,
    position: "relative",
  },
  productCard: {
    width: "300px",
    background: "white",
    border: `1px solid ${theme.palette.default.main}`,
    borderRadius: "8px",
    marginRight: "4px",

    "& .productCardContent": {
      display: "flex",
      alignItems: "flex-start",
      padding: "24px 24px 8px 24px",

      "& > div": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
    },

    "& .productCardInfo": {
      width: "100%",
      padding: "0 12px",
    },

    "& .viewDetail": {
      padding: "8px 24px 24px 24px",
      textAlign: "center",
      display: "block",

      "& > a": {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
    },
  },
  productPrice: {
    color: theme.palette.primary.main,
  },
  leftMessageContent: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "8px",
    "& .corner": {
      display: "inline",
      width: "0",
      height: "0",
      borderTop: "5px solid white",
      borderLeft: "5px solid transparent",
      marginLeft: 47,
    },
    "& .user": {
      marginBottom: 8,
      "&-avatar": {
        width: 35,
        height: 35,
        marginRight: 12,
      },
    },
    "& .image, .product-card": {
      marginLeft: 47,
    },
  },
  defaultText: {
    maxWidth: 423,
    height: "fit-content",
    minHeight: "40px",
    borderRadius: "8px",
    backgroundColor: "white",
    padding: "12px",
    wordBreak: "break-word",
  },
  leftText: {
    borderTopLeftRadius: "0px",
  },
  rightText: {
    backgroundColor: "#69CDAE",
    color: "white",
    borderTopRightRadius: "0px",
  },
  rightMessageContent: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "8px",
    "& .corner": {
      display: "inline",
      width: "0",
      height: "0",
      borderTop: `5px solid #69CDAE`,
      borderRight: "4px solid transparent",
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    height: 68,
    "& img ~ img": {
      marginLeft: 12,
    },
  },
  chatContent: {
    width: "86%",
    paddingLeft: "16px",

    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },

    "& .MuiInputBase-root": {
      borderRadius: "12px",
      height: "34px",
      padding: "0",
    },
  },
  imageContainer: {
    borderRadius: 8,
    backgroundColor: theme.palette.grey.main,
    maxWidth: 219,
    marginRight: 5,
  },
  imagesContainer: {
    padding: 2,
    cursor: "pointer",
  },
  smallImage: {
    borderRadius: 8,
    width: 69,
    height: 69,
    objectFit: "cover",
  },
  blurEffect: {
    filter: "blur(2px)",
  },
  deliveryIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
    alignSelf: "flex-end",
  },
  hidden: {
    visibility: "hidden",
  },
  link: {
    fontSize: 12,
    fontWeight: 500,
  },
  documentContainer: {
    display: "flex",
    alignItems: "center",
  },
  alignCenter: {
    alignItems: "center !important",
  },
  backButtonContainer: {
    alignSelf: "center",
    padding: 8,
  },
  senderInfo: {
    flex: 1,
  },
  fullName: {
    fontSize: 15,
    fontWeight: "bold",
  },
  messageContent: {
    fontSize: 12,
  },
  searchedTitle: {
    fontWeight: 500,
    textTransform: "capitalize",
  },
  resultContainer: {
    height: 565,
    overflowY: "auto",

    /* Works on firefox */
    scrollbarWidth: "thin",
    scrollbarColor: "#D8D8D8",

    /* Works on Chrome, Edge, and Safari */
    "&::-webkit-scrollbar": {
      width: "4px",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D8D8D8",
      borderRadius: 20,
    },
  },
  groupHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px 0",
  },
  seeMore: {
    color: theme.palette.primary.main,
    textTransform: "capitalize",
  },
  replyButton: {
    padding: 6,
    height: "fit-content",
    alignSelf: "center",
  },
  replyInfo: {
    padding: "8px 8px 8px 28px",
    background: "#ffffff",
    marginBottom: "-16px",
    position: "absolute",
    width: "100%",
    borderTop: `1px solid ${theme.palette.grey.main}`,
  },
  repliedMessage: {
    color: theme.palette.grey.text,
    width: "fit-content",
  },
  closeReplyButton: {
    padding: 0,
  },
  repliedMessageContainer: {
    background: "rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    padding: 8,
    width: "fit-content",
    marginBottom: "-8px",
    cursor: "pointer",
    maxWidth: 423,
  },
  borderRadius: {
    borderRadius: 8,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.default.main}`,
  },
  channelHeader: {
    height: 64,
    borderBottom: `1px solid ${theme.palette.default.main}`,
  },
  textGrey: {
    color: theme.palette.grey.label,
  },
  w100: {
    width: "100%",
  },
  noneMargin: {
    margin: "0 !important",
  },
}));
