import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "preferences",
  initialState: {
    appVersion: "1.0.0",
    whatsNewOpened: false,
  },
  reducers: {
    setAppVersion(state, action) {
      state.appVersion = action.payload;
    },
    setWhatsNewOpened(state, action) {
      state.whatsNewOpened = action.payload;
    }
  },
});

export const {
  setAppVersion: setAppVersionAction,
  setWhatsNewOpened: setWhatsNewOpenedAction,
} = slice.actions;

export const setWhatsNewOpened = (opened) => async (dispatch) => {
  dispatch(setWhatsNewOpenedAction(opened));
  localStorage.setItem("opened",JSON.stringify(opened))
};

export const setAppVersion = (version) => async (dispatch) => {
  dispatch(setAppVersionAction(version));
  localStorage.setItem("version",JSON.stringify(version))
};

export default slice.reducer;

