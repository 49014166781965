import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CHAT_SIDE_BAR_ITEMS, CHAT_SIDE_BAR_KEYS } from "./constant";
import { OutlinedTooltip } from "@components/Tooltip/Tooltip";
import clsx from "clsx";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRight: `1px solid ${theme.palette.default.main}`,
  },
  active: {
    background: "#FCEBE1",
  },
  menuItem: {
    position: "relative",
  },
  menuButton: {
    padding: "16px 20px",
    borderRadius: 0,
    height: 60,
    borderBottom: `1px solid ${theme.palette.default.main}`,
  },
  messagesCountDot: {
    background: "#F6133D",
    display: "inline-block",
    color: "#ffffff",
    width: 16,
    height: 16,
    borderRadius: "50%",
    fontSize: 12,
    fontWeight: 500,
    textAlign: "center",
    position: "absolute",
    right: 4,
    bottom: 8,
  },
}));

export default function ChatSideBar({ activeTab, onClickMenuItem }) {
  const classes = useStyles();
  const newMessagesCount = useSelector((state) => state.chat.newMessageCount);

  const renderMessagesCountDot = (menuItem) => {
    let component = null;
    switch (menuItem.key) {
      case CHAT_SIDE_BAR_KEYS.CHAT:
        if (newMessagesCount) {
          component = (
            <span className={classes.messagesCountDot}>
              {newMessagesCount < 10 ? newMessagesCount : "9+"}
            </span>
          );
        }
        break;
      default:
        break;
    }

    return component;
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.menuList} component="ul">
        {CHAT_SIDE_BAR_ITEMS.map((item) => (
          <Box
            key={item.key}
            className={clsx({
              [classes.menuItem]: true,
              [classes.active]: activeTab === item.key,
            })}
            component="li"
          >
            <OutlinedTooltip title={item.label} arrow placement="top-center">
              <IconButton
                className={classes.menuButton}
                onClick={() => onClickMenuItem(item)}
              >
                <img src={item.icon} alt={item.key} width={28} height={28} />
              </IconButton>
            </OutlinedTooltip>
            {renderMessagesCountDot(item)}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
