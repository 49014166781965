import { Box, IconButton, Typography, Grid } from "@material-ui/core";
import React from "react";
import {
  Close as ClosIcon,
  AttachFile as AttachFileIcon,
} from "@material-ui/icons";
import { useStyles } from "./styles";

export default function ReplyInfo({
  setOpenReplyInfo,
  repliedMessage,
  setRepliedMessage,
  messageInputRef,
}) {
  const classes = useStyles();

  const renderRepliedMessage = () => {
    let content = null;

    switch (repliedMessage?.type) {
      case "text":
        content = (
          <Typography className={classes.repliedMessage}>
            {repliedMessage?.message}
          </Typography>
        );
        break;
      case "video":
        content = (
          <Typography className={classes.repliedMessage}>
            Attached Video
          </Typography>
        );
        break;
      case "voice":
        content = (
          <Typography className={classes.repliedMessage}>
            Attached Voice
          </Typography>
        );
        break;
      case "photo":
        content = (
          <Box paddingTop={0.5} display="flex" flexWrap="wrap">
            {repliedMessage?.files?.map((item, index) => (
              <img
                key={index}
                src={item?.url}
                alt="Replied image"
                width={50}
                style={{
                  objectFit: "contain",
                  padding: "0 4px",
                }}
              />
            ))}
          </Box>
        );
        break;
      case "product":
        content = (
          <Typography className={classes.repliedMessage}>
            Product attachment
          </Typography>
        );
        break;
      default:
        content = (
          <Box display="flex" paddingTop={0.5}>
            <Typography
              className={classes.repliedMessage}
              style={{ marginRight: 8 }}
            >
              Attached Document
            </Typography>
            <AttachFileIcon fontSize="small" />
          </Box>
        );
    }

    return content;
  };

  return (
    <Box
      className={classes.replyInfo}
      display="flex"
      justifyContent="space-between"
      // alignItems="center"
      id="reply-info"
    >
      <Box>
        <Typography>
          Replying to{" "}
          <span className="fw-bold">
            {repliedMessage?.isMyMessage ? "You" : repliedMessage?.senderName}
          </span>
        </Typography>
        {renderRepliedMessage()}
      </Box>
      <Box>
        <IconButton className={classes.closeReplyButton}>
          <ClosIcon
            fontSize="small"
            onClick={() => {
              setOpenReplyInfo(false);
              setRepliedMessage(null);
              messageInputRef?.current?.focus();
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
}
