import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import {
  Typography,
  Grid,
  Avatar,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  Done as SentIcon,
  DoneAll as SeenIcon,
  Description as DescriptionIcon,
  Reply as ReplyIcon,
  AttachFile as AttachFileIcon,
} from "@material-ui/icons";
import { useStyles } from "./styles";
import {
  formatDate,
  formatPrice,
  formatTime12,
  getFullTime,
  getSubProductTypeDefaultImages,
  getThumbImageUrl,
} from "@utils/index";
import { FruText } from "@components";
import { blobUrl } from "utils";
import Link from "next/link";
import { useSelector } from "react-redux";

import ReplyInfo from "./ReplyInfo";
import { getFileType } from "@services/chat";
import { width } from "@mui/system";

export default function Chat({
  userId,
  messages,
  membersInfo = {},
  setCurrentMedia,
  setCurrentMediaIndex,
  lastVisit,
  scrollToBottom,
  messageInputRef,
  repliedMessage,
  setRepliedMessage,
  openReplyInfo,
  setOpenReplyInfo,
  handleJumpToSearchedMessage,
  currentChannel,
}) {
  const classes = useStyles();
  const [showReplyButtonMessage, setShowReplyButtonMessage] = useState(null);

  const productTypes = useSelector((state) => state.global.productTypes);
  const replyInfoRef = useRef(null);

  useEffect(() => {
    if (replyInfoRef.current) {
      replyInfoRef.current.scrollIntoView();
    }
  }, [replyInfoRef.current]);

  const formattedMessages = useMemo(
    () =>
      messages
        .map((msg) => ({
          message: msg.message,
          date: msg.createAt,
          avatar: membersInfo[msg?.senderId]?.photo?.url,
          isMyMessage: msg?.senderId === userId,
          formattedDate: getFullTime(msg.createAt),
          createAt: msg.createAt,
          type: msg.type,
          blobData: msg?.file?.constructor === File ? msg?.file : null,
          id: msg.id,
          files: msg?.files,
          isPending: msg?.isPending,
          createAt: msg?.createAt,
          productAttachment: msg?.productAttachment,
          isSystemMessage: msg?.isSystemMessage,
          senderName: msg?.name,
          linkedMessageId: msg?.linkedMessageId,
          linkedMessage: msg?.linkedMessage,
          linkedFiles: msg?.linkedFiles,
          linkedProductAttachment: msg?.linkedProductAttachment,
        }))
        .reverse(),
    [messages, membersInfo]
  );

  const handleScrollToRepliedMessage = (linkedMessageId) => {
    const element = document.getElementById(linkedMessageId);

    if (element) {
      element?.scrollIntoView({
        block: "center",
      });
    } else {
      handleJumpToSearchedMessage(currentChannel?.id, linkedMessageId);
    }
  };

  const renderTimeStamp = (message) => {
    let formattedDate = message?.formattedDate;

    if (
      formattedDate?.includes("Today") ||
      formattedDate?.includes("Yesterday")
    ) {
      formattedDate = `${formattedDate?.split(",")[0]} - ${formatTime12(
        message?.createAt
      )}`;
    } else {
      formattedDate = `${formatDate(formattedDate)} - ${formatTime12(
        formattedDate
      )}`;
    }

    return new Date(message.date) !== new Date() ? (
      <FruText
        className={clsx({
          [classes.w100]: message?.isMyMessage,
          "flex-center-y": true,
        })}
        textAlign="start"
        color="grey"
        fontSize="10px"
      >
        {formattedDate}
      </FruText>
    ) : null;
  };

  const renderMedia = (file, type, isSmall, message, index) => {
    if (type === "photo")
      return (
        <>
          {!index && message?.isMyMessage && renderTimeStamp(message)}
          <img
            src={
              file?.url
                ? file?.url
                : file?.constructor === File
                ? blobUrl(file)
                : null
            }
            alt="Open Image"
            className={clsx(classes.imageContainer, {
              [classes.blurEffect]: !file?.url,
              [classes.imageContainer]: !isSmall,
              [classes.smallImage]: isSmall,
            })}
          />
        </>
      );
    else if (type === "video")
      return (
        <>
          {!index && message?.isMyMessage && renderTimeStamp(message)}
          <video controls className={clsx(classes.imageContainer)}>
            <source src={file?.url} />
          </video>
        </>
      );
    else if (type === "voice")
      return (
        <>
          {!index && message?.isMyMessage && renderTimeStamp(message)}
          <audio controls>
            <source src={file?.url} />
          </audio>
        </>
      );
    else if (type === "document")
      return (
        <>
          {!index && message?.isMyMessage && renderTimeStamp(message)}
          <div
            onClick={() => {
              window.open(file?.url, "_blank");
            }}
            className={clsx(classes.defaultText, classes.documentContainer)}
          >
            <DescriptionIcon style={{ color: "#BEBEBE" }} />
            <Typography style={{ marginLeft: 12 }}>{file?.name}</Typography>
          </div>
        </>
      );
  };

  const renderMedias = (files, type, productAttachment, message) => {
  
    if (type === "product" && productAttachment)
      return (
        <Box>
          <Box className="user" display="flex" alignItems="center">
            {!message?.isMyMessage ? (
              <>
                <Avatar
                  className="user-avatar"
                  src={message?.avatar}
                  alt="User avatar"
                />
                <Box display="flex">
                  <Typography
                    className="fw-bold text-sm"
                    style={{ marginRight: 8 }}
                  >
                    {message?.senderName}
                  </Typography>
                  {renderTimeStamp(message)}
                </Box>
              </>
            ) : (
              renderTimeStamp(message)
            )}
          </Box>
          <Grid
            id={message.id}
            className={clsx(classes.productCard, "product-card")}
          >
            <Grid className={clsx("productCardContent")}>
              <img
                alt="product image"
                height="60px"
                src={
                  getThumbImageUrl(productAttachment) ||
                  getSubProductTypeDefaultImages(
                    productAttachment,
                    productTypes
                  )[0]?.thumbUrl
                }
              />
              <Grid>
                <Grid className="productCardInfo">
                  <FruText fontWeight={500}>
                    {productAttachment?.name || ""}
                  </FruText>
                  <FruText color="grey" fontSize="11px" margin="4px 0 0 0">
                    {productAttachment?.category || ""}
                  </FruText>
                </Grid>
                {productAttachment?.isHidePrice ? (
                  <FruText color="primary" fontSize="12px">
                    Price on request
                  </FruText>
                ) : (
                  <FruText color="primary" fontSize="16px">
                    {productAttachment.currency?.symbol}
                    {formatPrice(productAttachment?.priceGuide)}
                  </FruText>
                )}
              </Grid>
            </Grid>
            <hr />
            <Grid className={clsx(classes.center, "viewDetail")}>
              <Link
                color="primary"
                href={{
                  pathname: `/produce-explore/[id]`,
                  query: { id: productAttachment.id },
                }}
              >
                View detail
              </Link>
            </Grid>
          </Grid>
        </Box>
      );
    if (!files || files?.length <= 0) return null;
    else if (files.length === 1)
      return files.map((item, index) => (
        <Box>
          <Box className="user" display="flex" alignItems="center">
            {!message?.isMyMessage && (
              <>
                <Avatar
                  className="user-avatar"
                  src={message?.avatar}
                  alt="User avatar"
                />
                <Box display="flex">
                  <Typography
                    className="fw-bold text-sm"
                    style={{ marginRight: 8 }}
                  >
                    {message?.senderName}
                  </Typography>
                  {renderTimeStamp(message)}
                </Box>
              </>
            )}
          </Box>
          <Box
            key={item.url}
            className={clsx(classes.imagesContainer, "image")}
            onClick={() => {
              // currently only show media viewer for photo type
              if (type !== "photo") return;
              setCurrentMedia(files);
              setCurrentMediaIndex(index);
            }}
            display="flex"
            flexDirection="column"
            alignItems={message?.isMyMessage ? "flex-end" : "flex-start"}
            id={message.id}
          >
            {message?.linkedMessageId && renderRepliedMessage(message)}
            {renderMedia(item, type, false, message, index)}
          </Box>
        </Box>
      ));
    else
      return (
        <Box>
          <Box className="user" display="flex" alignItems="center">
            {!message?.isMyMessage && (
              <>
                <Avatar
                  className="user-avatar"
                  src={message?.avatar}
                  alt="User avatar"
                />
                <Box display="flex">
                  <Typography
                    className="fw-bold text-sm"
                    style={{ marginRight: 8 }}
                  >
                    {message?.senderName}
                  </Typography>
                  {renderTimeStamp(message)}
                </Box>
              </>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems={message?.isMyMessage ? "flex-end" : "flex-start"}
            id={message.id}
          >
            {message?.linkedMessageId && renderRepliedMessage(message)}
            <Box
              display="flex"
              flexWrap="wrap"
              maxWidth="219px"
              marginRight="10px"
              justifyContent={message?.isMyMessage ? "flex-end" : "flex-start"}
              className="image"
            >
              {files.map((item, index) => (
                <div
                  onClick={() => {
                    // currently only show media viewer for photo type
                    if (type !== "photo") return;
                    setCurrentMedia(files);
                    setCurrentMediaIndex(index);
                  }}
                  className={classes.imagesContainer}
                >
                  {renderMedia(item, type, true, message, index)}
                </div>
              ))}
            </Box>
          </Box>
        </Box>
      );
  };

  const renderDeliveryStatus = ({ isPending, createAt }) => {
    if (isPending)
      return (
        <SentIcon className={clsx(classes.deliveryIcon, classes.hidden)} />
      );
    else if (createAt < lastVisit) {
      return (
        <Box display="flex" alignItems="flex-end">
          <img src={`/static/svg/ic_sent.svg`} alt="Seen icon" />
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="flex-end">
          {currentChannel?.isBroadcast ? (
            <img
              src={`/static/svg/ic_broadcast_sent.svg`}
              alt="Broadcast sent icon"
            />
          ) : (
            <SentIcon className={classes.deliveryIcon} />
          )}
        </Box>
      );
    }
  };

  const renderReplyButton = (message) => {
    if (currentChannel?.isBroadcast) return null;
    return (
      showReplyButtonMessage?.id === message?.id && (
        <Tooltip title="Reply" placement="top-center">
          <IconButton
            className={classes.replyButton}
            onClick={() => {
              setRepliedMessage(message);
              setOpenReplyInfo(true);
              scrollToBottom();
              messageInputRef?.current?.focus();
            }}
          >
            <ReplyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )
    );
  };

  const renderRepliedMessage = (message) => {
    let repliedMessageContent = (
      <Typography className={classes.repliedMessage}>
        {message?.linkedMessage}
      </Typography>
    );

    if (message?.linkedFiles?.length) {
      let repliedMessageType = "text";
      repliedMessageType = getFileType(message?.linkedFiles[0]);

      switch (repliedMessageType) {
        case "video":
          repliedMessageContent = (
            <Box display="flex">
              <Typography
                className={classes.repliedMessage}
                style={{ marginRight: 8 }}
              >
                Attached Video
              </Typography>
              <AttachFileIcon fontSize="small" />
            </Box>
          );
          break;
        case "voice":
          repliedMessageContent = (
            <Box display="flex">
              <Typography
                className={classes.repliedMessage}
                style={{ marginRight: 8 }}
              >
                Attached Voice Message
              </Typography>
              <AttachFileIcon fontSize="small" />
            </Box>
          );
          break;
        case "document":
          repliedMessageContent = (
            <Box display="flex">
              <Typography
                className={classes.repliedMessage}
                style={{ marginRight: 8 }}
              >
                Attached Document
              </Typography>
              <AttachFileIcon fontSize="small" />
            </Box>
          );
          break;
        case "photo":
          repliedMessageContent = (
            <Box>
              <img
                src={message?.linkedFiles[0].url}
                width={80}
                style={{
                  opacity: 0.5,
                }}
              />
            </Box>
          );
          break;
        default:
          break;
      }
    } else if (message?.linkedProductAttachment) {
      let productAttachment = message.linkedProductAttachment;

      repliedMessageContent = (
        <Box style={{ opacity: 0.5 }}>
          <Grid className={classes.productCard}>
            <Grid className={clsx("productCardContent")}>
              <img
                alt="product image"
                height="60px"
                src={
                  getThumbImageUrl(productAttachment) ||
                  getSubProductTypeDefaultImages(
                    productAttachment,
                    productTypes
                  )[0]?.thumbUrl
                }
              />
              <Grid>
                <Grid className="productCardInfo">
                  <FruText fontWeight={500}>
                    {productAttachment?.name || ""}
                  </FruText>
                  <FruText color="grey" fontSize="11px" margin="4px 0 0 0">
                    {productAttachment?.category || ""}
                  </FruText>
                </Grid>
                <FruText color="primary" fontSize="16px">
                  {productAttachment.currency?.symbol}
                  {formatPrice(productAttachment?.priceGuide)}
                </FruText>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return (
      <Box
        className={classes.repliedMessageContainer}
        onClick={(e) => {
          e.stopPropagation();
          handleScrollToRepliedMessage(message?.linkedMessageId);
        }}
      >
        {repliedMessageContent}
      </Box>
    );
  };

  const renderMessage = (message) => {
    let content = (
      <>
        <Box className="user" display="flex" alignItems="center">
          {!message?.isMyMessage && (
            <>
              <Avatar
                className="user-avatar"
                src={message?.avatar}
                alt="User avatar"
              />
              <Box display="flex">
                <Typography
                  className="fw-bold text-sm"
                  style={{ marginRight: 8 }}
                >
                  {message?.senderName}
                </Typography>
                {renderTimeStamp(message)}
              </Box>
            </>
          )}
        </Box>
        {message?.isMyMessage && renderTimeStamp(message)}
        <Box display="flex" id={message.id}>
          {!message?.isMyMessage && <div className="corner" />}
          <div
            className={clsx(
              classes.defaultText,
              message?.isMyMessage ? classes.rightText : classes.leftText
            )}
          >
            <Typography>{message.message}</Typography>
          </div>
          {message?.isMyMessage && <div className="corner" />}
        </Box>
      </>
    );

    if (message?.linkedMessageId) {
      content = (
        <>
          {message?.isMyMessage ? (
            renderTimeStamp(message)
          ) : (
            <Box display="flex" alignItems="center" paddingBottom={1}>
              <Avatar
                className="user-avatar"
                src={message?.avatar}
                alt="User avatar"
              />
              <Box display="flex">
                <Typography
                  className="fw-bold text-sm"
                  style={{ marginRight: 8 }}
                >
                  {message?.senderName}
                </Typography>
                {renderTimeStamp(message)}
              </Box>
            </Box>
          )}
          <Box marginLeft={!message?.isMyMessage ? "47px" : 0}>
            {renderRepliedMessage(message)}
            <Box
              display="flex"
              id={message.id}
              justifyContent={message?.isMyMessage ? "flex-end" : "flex-start"}
            >
              <div
                className={clsx(
                  classes.defaultText,
                  message?.isMyMessage ? classes.rightText : classes.leftText,
                  classes.borderRadius
                )}
              >
                <Typography>{message.message}</Typography>
              </div>
            </Box>
          </Box>
        </>
      );
    }
    return content;
  };

  return (
    <>
      {formattedMessages.map((item, idx) => {
        const date = new Date(item.date);
        return item.isMyMessage ? (
          !item.isSystemMessage ? (
            <Grid key={item.id} className={classes.pd12}>
              {/* {date !== new Date() ? (
                <FruText textAlign="end" color="grey" fontSize="10px">
                  {item.formattedDate}
                </FruText>
              ) : null} */}
              <Grid
                container
                justifyContent="flex-end"
                onMouseOver={() => setShowReplyButtonMessage(item)}
                onMouseLeave={() => setShowReplyButtonMessage(null)}
              >
                <Grid className={classes.rightMessageContent}>
                  {item.type !== "text" ? (
                    <Box display="flex">
                      {renderReplyButton(item)}
                      {renderMedias(
                        item?.files,
                        item.type,
                        item?.productAttachment,
                        item
                      )}
                    </Box>
                  ) : item?.productAttachment ? (
                    <Box display="flex">
                      {renderReplyButton(item)}

                      <Box
                        style={{
                          borderRadius: 10,
                          backgroundColor: "white",
                          padding: "10px",
                        }}
                      >
                        <Typography>
                          <img
                            src="/static/svg/ic_star.svg"
                            style={{ marginRight: "10px" }}
                            width={15}
                          />{" "}
                          {item.senderName} has shared a post with you
                        </Typography>
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            paddingTop: "8px",
                          }}
                        >
                          <a
                            href={`/post-share?id=${item?.productAttachment?.postId}`}
                            style={{ textDecoration: "none", color: "#e77128" }}
                          >
                            Go to Post
                            <img
                              src="/static/svg/ic_attachment.svg"
                              style={{ marginRight: "10px", marginLeft: "5px" }}
                              width={15}
                            />
                          </a>
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Box display="flex">
                        {renderReplyButton(item)}
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-end"
                          position="relative"
                        >
                          {item?.message && renderMessage(item)}
                        </Box>
                      </Box>
                    </>
                  )}
                </Grid>
                {renderDeliveryStatus(item)}
              </Grid>
            </Grid>
          ) : (
            <Grid key={item.id} className={classes.pd12}>
              <Grid container className={classes.systemText}>
                <FruText color="grey" fontSize="12px">
                  {item.message}
                </FruText>
              </Grid>
            </Grid>
          )
        ) : !item.isSystemMessage ? (
          <Grid key={item.id} className={classes.pd12}>
            <Grid
              container
              className={classes.pd12}
              onMouseOver={() => setShowReplyButtonMessage(item)}
              onMouseLeave={() => setShowReplyButtonMessage(null)}
            >
              {/* {shouldShowName ? (
                <Avatar alt="avatar" src={item.avatar} />
              ) : null} */}
              <Grid className={classes.leftMessageContent}>
                {item.type !== "text" ? (
                  <Box display="flex">
                    {renderMedias(
                      item?.files,
                      item.type,
                      item?.productAttachment,
                      item
                    )}
                    {renderReplyButton(item)}
                  </Box>
                ) : (
                  <>
                    {/* {shouldShowName ? (
                      <FruText color="grey" fontSize="12px">
                        {item.senderName}
                      </FruText>
                    ) : (
                      ""
                    )} */}
                    <Box display="flex">
                      <Box>{item?.message && renderMessage(item)}</Box>
                      {renderReplyButton(item)}
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid key={item.id} className={classes.pd12}>
            <Grid container className={classes.systemText}>
              <FruText color="grey" fontSize="12px">
                {item.message}
              </FruText>
            </Grid>
          </Grid>
        );
      })}
      {openReplyInfo && (
        <Box ref={replyInfoRef}>
          <ReplyInfo
            setOpenReplyInfo={setOpenReplyInfo}
            repliedMessage={repliedMessage}
            setRepliedMessage={setRepliedMessage}
            messageInputRef={messageInputRef}
          />
        </Box>
      )}
    </>
  );
}
