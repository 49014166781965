import { reduxBatch } from "@manaflair/redux-batch";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

import reducer from "reducers";
const persistedReducer = persistReducer(persistConfig, reducer)

export default function createStore(preloadedState = {}, context) {
  return configureStore({
    reducer:persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== "production",
    preloadedState,
    enhancers: [reduxBatch],
  });
}
