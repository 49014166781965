import React from "react";
import { Typography, Menu, MenuItem, Link } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  root: {
    "& .menu-item-label": {
      fontSize: 12,
      paddingLeft: 8,
    },
    "& .MuiPopover-paper": {
      minWidth: (props) => props.menuWidth,
      marginTop: 6,
    },
    "& a:hover, a:visited": {
      color: "inherit",
    },
  },
});

export default function OrdersMenu({ anchorEl, handleClose }) {
  const classes = useStyles({ menuWidth: anchorEl?.offsetWidth });
  const MENU_ITEMS = [
    {
      key: "purchases",
      label: "Purchases",
      href: "/purchases/orders-placed",
    },
    {
      key: "sales",
      label: "Sales",
      href: "/sales/orders-received",
    },
  ];

  return (
    <div>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        className={classes.root}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {MENU_ITEMS?.map((item, index) => (
          <MenuItem key={index} className="menu-item">
            <Link
              href={item.href}
              underline="none"
              color="inherit"
              style={{
                width: "100%",
              }}
            >
              <Typography className="menu-item-label fw-medium text-sm">
                {item.label}
              </Typography>
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
