import React from "react";
import styled from "styled-components";
import Link from "next/link";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Root = styled.header`
  background: white;
  .title {
    font-size: 20px;
    font-weight: 600;
    color: #69cdae;
    text-transform: capitalize !important;
  }
  .info {
    color: ${(props) => props.theme.texts.primary};
    & span {
      padding-left: 12px;
    }
  }
  .subtitle {
    color: ${(props) => props.theme.texts.secondary};
  }
  .copyright {
    font-size: 13px;
    color: ${(props) => props.theme.texts.secondary};
    & div,
    span,
    a {
      font-size: 15px;
      font-weight: 500;
      color: #858688;
    }
  }
  .social-icon {
    width: 20px;
    margin-right: 12px;
  }
  a {
    color: inherit;
    &:hover {
      color: #e77228;
    }
  }
  .social-item-list {
    display: block;
    float: left;
  }
  .list-text {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .list-item {
    margin-bottom: 20px;
  }
`;

export default function Index(props) {
  return (
    <Root>
      <div className="container py-5">
        <div className="row w-100">
          {/* About Start START!--->>>>>>>>>>>>>>>>> */}
          <Col lg="3" sm="6">
            <h6 className="fw-bold title mb-3">About Us</h6>
            <ul>
              <li className="list-item">
                <a
                  className="list-text"
                  href="https://frupro.com/our-story/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  About FruPro
                </a>
              </li>
              <li className="list-item">
                <a
                  className="list-text"
                  href="https://frupro.com/faqs/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  FAQ’s
                </a>
              </li>
            </ul>
          </Col>
          {/* Privacy Policy START!--->>>>>>>>>>>>>>>>> */}
          <Col lg="3" sm="6">
            <h6 className="fw-bold title mb-3">Privacy</h6>
            <ul>
              <li className="list-item">
                <a
                  className="list-text"
                  href="https://frupro.com/legal/terms-and-conditions/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  Terms & Conditions
                </a>
              </li>
              <li className="list-item">
                <a
                  className="list-text"
                  href="https://frupro.com/legal/app-terms-and-conditions/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  App Terms & Conditions
                </a>
              </li>
              <li className="list-item">
                <a
                  className="list-text"
                  href="https://frupro.com/legal/privacy-and-cookie-policy/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  Privacy & Cookie Policy
                </a>
              </li>
            </ul>
          </Col>
          {/* Contact Us START!--->>>>>>>>>>>>>>>>> */}
          <Col lg="3" sm="6">
            <h6 className="fw-bold title mb-3 text-uppercase">Contact Us</h6>
            <ul>
              <li className="list-item">
                <img
                  src={`/static/svg/new_message_light.svg`}
                  style={{ marginRight: "13px" }}
                  width={25}
                />
                <span style={{ textDecoration: "underline" }}>
                  hello@frupro.com
                </span>
              </li>
              <li className="list-item">
                <img
                  src={`/static/svg/footer_phone.svg`}
                  style={{ marginRight: "15px", marginLeft: "5px" }}
                  width={18}
                />
                <span className="list-text">+44 7700 159867</span>
              </li>
            </ul>
            {/* Follow Us START!--->>>>>>>>>>>>>>>>> */}
            <h6
              className="fw-bold dark mb-3"
              style={{ fontSize: "20px", fontWeight: "600", marginTop: "22px" }}
            >
              Follow Us
            </h6>
            <ul className="social-item-list">
              <li>
                <a href="https://twitter.com/fruproworld" target="_blank">
                  <img
                    className="social-icon"
                    src="/static/svg/new_twitter_icon.svg"
                  />
                </a>
                <a href="https://www.instagram.com/fruproworld" target="_blank">
                  <img
                    className="social-icon"
                    src="/static/svg/new_instagram_icon.svg"
                  />
                </a>
                <a href="https://www.facebook.com/fruproworld/" target="_blank">
                  <img
                    className="social-icon"
                    src="/static/svg/new_facebook_icon.svg"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/frupro"
                  target="_blank"
                >
                  <img
                    className="social-icon"
                    src="/static/svg/new_linkedin_icon.svg"
                  />
                </a>
              </li>
            </ul>
          </Col>
          {/* Download Platform START!--->>>>>>>>>>>>>>>>> */}
          <Col lg="3" sm="6">
            <h6 className="fw-bold title">
              Download <br />
              Mobile App
            </h6>
            <div className="mt-4">
              <div>
                <a
                  href="https://apps.apple.com/vn/app/frupro-trade-fresh-produce/id1540040041"
                  target="_blank"
                >
                  <img src={`/static/svg/ic_app_store.svg`} />
                </a>
              </div>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.frupro.user"
                  target="_blank"
                >
                  <img src={`/static/svg/ic_google_play.svg`} />
                </a>
              </div>
            </div>
          </Col>
        </div>
      </div>
      {/* Copyright Text START!--->>>>>>>>>>>>>>>>> */}
      <hr className="mt-5" />
      <div className="container">
        <div className="d-flex justify-content-center copyright pb-3">
          <div>Copyright © 2022 FruPro Ltd. All rights reserved.</div>
        </div>
      </div>
    </Root>
  );
}
