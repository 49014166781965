import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, Button } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import { FruProButton } from "@components";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";

const useStyles = makeStyles((theme) => ({
  mobileContainer: {
    height: "100vh",
    background: "#f9f9f9f9",
    "& svg": {
      color: "#E0993F",
      width: "1.5em",
      height: "1.5em",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  downloadButton: {
    boxShadow: "none",
    fontSize: "11px !important",
    textTransform: "initial !important",
    margin: "0 !important",
    borderRadius: "6px !important",
  },
  content: {
    paddingBottom: 24,
    "& p": {
      color: theme.palette.grey.text,
    },
  },
}));

export default function DownloadApp() {
  const classes = useStyles();
  const router = useRouter();
  const deactiveThisPage = false;

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    // if(window.innerWidth<768 && !user) router.push("https://fruproprod.page.link/fnb2")
  }, []);

  return (
    <Box className="hide-on-pc">
      <Box className={classes.mobileContainer}>
        <Box>
          <img width="170" src="/static/images/logo.png" />
        </Box>
        <Box padding="12px 0">
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
          <StarIcon />
        </Box>
        <Box className={classes.content}>
          <Typography align="center">
            For a better experience please use the app.
          </Typography>
          <Typography align="center">You can download it for free:</Typography>
        </Box>
        <Box>
          <FruProButton
            className={classes.downloadButton}
            variant="contained"
            color="success"
            href={`https://fruproqa.page.link/app`}
          >
            Download the mobile app
          </FruProButton>
        </Box>
      </Box>
    </Box>
  );
}
