import { axiosInstance } from "services";
import toastr from "toastr";
import { uploadFile, uploadDocumentFile } from "@services/common";
import { queryObjSerialize } from "@utils/index";
import { normalizeObjectData } from "../utils";

export const companyService = {
  async register(_data) {
    const { data } = await axiosInstance.post("/companies/register", _data);
    return data;
  },
};

export const getCompany = async (companyId) => {
  try {
    const { data, status } = await axiosInstance.get(`/companies/${companyId}`);

    if (status === 200) return data;
    return null;
  } catch (error) {
    console.error(error);
  }
};

export const registerCompany = async (companyData) => {
  try {
    // let logoData;
    // if (companyData.logo && companyData.logo?.constructor === File) {
    //   logoData = await uploadFile(companyData.logo);
    // }
    // const listAttachment = await Promise.all(
    //   companyData.attachment.map(async (att) => {
    //     if (att?.constructor === File) {
    //       const attachmentData = await uploadFile(att);
    //       return attachmentData;
    //     } else return att;
    //   })
    // );

    const normalizedCompanyData = normalizeObjectData(companyData);

    const { data, status } = await axiosInstance.post(`/companies/register`, {
      ...normalizedCompanyData,
      // logo: logoData || companyData.logo,
      // attachment: listAttachment,
    });
    if (status === 200) return { data, isSuccess: true };
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
  }
};

export const editGeneralCompany = async (companyId, companyData) => {
  try {
    let logoData;
    if (companyData.logo && companyData.logo?.constructor === File) {
      logoData = await uploadFile(companyData.logo);
    }
    const listAttachment = await Promise.all(
      companyData.attachment.map(async (att) => {
        if (att?.constructor === File) {
          const attachmentData = await uploadFile(att);
          return attachmentData;
        } else {
          let attWithoutId = { ...att };
          delete attWithoutId._id;
          delete attWithoutId.url;
          return attWithoutId;
        }
      })
    );
    const listDocuments = await Promise.all(
      companyData.documents.map(async (att) => {
        if (att?.constructor === File) {
          const documentData = await uploadDocumentFile(att);
          return documentData;
        } else {
          let attWithoutId = { ...att };
          delete attWithoutId._id;
          delete attWithoutId.url;
          return attWithoutId;
        }
      })
    );
    const { data, status } = await axiosInstance.put(
      `/companies/${companyId}`,
      {
        ...companyData,
        logo: logoData || companyData.logo,
        attachment: listAttachment,
        documents: listDocuments,
      }
    );
    if (status === 200) return data;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const editConfidentialityCompany = async (
  companyId,
  confidentialityData
) => {
  try {
    const { data, status } = await axiosInstance.put(
      `/companies/${companyId}/confidentiality`,
      confidentialityData
    );
    if (status === 200) return data;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const getLastestConfidentiality = async (companyId) => {
  try {
    const { data, status } = await axiosInstance.get(
      `/companies/${companyId}/latest-request-confidentiality`
    );
    if (status === 200) return data;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const cancelLastestConfidentiality = async (companyId) => {
  try {
    const { data, status } = await axiosInstance.delete(
      `/companies/${companyId}/latest-request-confidentiality`
    );
    if (status === 200) return data;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const updateCompanyCover = async (cover, companyId, type) => {
  try {
    if (!cover || !companyId) return;
    let coverImage = await uploadFile(cover);

    let body =
      type === "logo" ? { logo: coverImage } : { coverImage: coverImage };

    const { data, status } = await axiosInstance.put(
      `/companies/${companyId}/image-change`,
      body
    );
    if (status === 200) return data;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const getListSellerCompany = async (queryParams) => {
  try {
    const queryStrings = queryObjSerialize(queryParams);
    const { data, status } = await axiosInstance.get(
      `/seller-offer-companies?${queryStrings}`
    );
    if (status === 200) return data;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const getListBuyerCompany = async (queryParams) => {
  try {
    const queryStrings = queryObjSerialize(queryParams);
    const { data, status } = await axiosInstance.get(
      `/buyer-offer-companies?${queryStrings}`
    );
    if (status === 200) return data;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const confirmUpdateConfidential = async (queryParams) => {
  try {
    const queryStrings = queryObjSerialize(queryParams);
    const { data, status } = await axiosInstance.get(
      `/confirm-update-confidential?${queryStrings}`
    );
    if (status === 200 || status === 204) {
      return {
        isSuccess: true,
        data,
      };
    }
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
  }
};

export const getAllEmployees = async (companyId) => {
  try {
    const { data, status } = await axiosInstance.get(
      `/companies/${companyId}/entire-employees`
    );
    if (status === 200) return data.items;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const getCompanyProducts = async (companyId, userId) => {
  try {
    const { data } = await axiosInstance.get(
      userId
        ? `/companies/${companyId}/produces?userId=${userId}`
        : `/companies/${companyId}/produces`
    );
    if (data) return data.items;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const getUserDemands = async (userId) => {
  try {
    const { data } = await axiosInstance.get(
      `/demand-produces?userId=${userId}`
    );
    if (data) return data.items;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const getCompanyDemands = async (companyId) => {
  try {
    const { data } = await axiosInstance.get(
      `/companies/${companyId}/top-demand-produces`
    );
    if (data) return data.items;
    return null;
  } catch (error) {
    let err = error.errors ? error.errors[0] || error : error;
    console.error(err);
    toastr.error(err.message);
    throw err;
  }
};

export const addCompanyImages = async (data) => {
  try {
    const response = await axiosInstance.post(
      "company-images/image-upload",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response) {
      toastr.success("Image is uploaded successfully.");
      return response.data;
    }
  } catch (err) {
    console.error(err);
    toastr.error(err?.response?.data?.message);
  }
};

export const getCompanyImages = async () => {
  try {
    const response = await axiosInstance.get(
      "/company-images/my-company-images"
    );
    if (response) return response.data;
  } catch (err) {
    console.error(err);
    toastr.error(err?.response?.data?.message);
  }
};

export const getOtherCompanyImages = async (companyId) => {
  try {
    const response = await axiosInstance.get(
      `/company-images/my-company-images?companyId=${companyId}`
    );
    if (response) return response.data;
  } catch (err) {
    console.error(err);
    toastr.error(err?.response?.data?.message);
  }
};

export const deleteCompanyImages = async (imageId) => {
  try {
    const response = await axiosInstance.post("/company-images/delete-image", {
      imageId,
    });
    if (response) {
      toastr.success("Image is deleted successfully.");
      return response.data;
    }
  } catch (err) {
    console.error(err);
    toastr.error(err?.response?.data?.message);
  }
};

export const uploadCertificate = async (formData) => {
  try {
    const response = await axiosInstance.post(
      "/company-certifications/company/upload-certification-document",
      formData,
      {
        headers: {
          accept: "application/json",

          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response) {
      toastr.success(
        "Your certification is uploaded successfully. After approval, it will be listed in your company profile page."
      );
      return response.data;
    }
  } catch (err) {
    console.error(err);
    toastr.error(err?.message);
  }
};

export const getCertificationTypes = async () => {
  try {
    const response = await axiosInstance.post(
      "company-certifications/company/get-certification-types",
      {
        page: 1,
        limit: 100,
      }
    );
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.error(err);
    toastr.error(err?.response?.data?.message);
  }
};

export const deleteCertification = async (id) => {
  try {
    const response = await axiosInstance.post(
      "company-certifications/company/remove-uploaded-certificate-document",
      {
        certificationRequestId: id,
      }
    );
    if (response) {
      toastr.success("Document is deleted successfully.");
      return response.data;
    }
  } catch (err) {
    console.error(err);
    toastr.error(err?.response?.data?.message);
  }
};
