import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "route",
  initialState: {
    routes: [],
    asPath: "",
  },
  reducers: {
    addCustomBreadcrumbs(state, action) {
      state.routes = action.payload.routes;
      state.asPath = action.payload.asPath;
    },
  },
});

export const { addCustomBreadcrumbs: addCustomBreadcrumbsAction } =
  slice.actions;

export const addCustomBreadcrumbs =
  ({ routes, asPath }) =>
  async (dispatch) => {
    dispatch(addCustomBreadcrumbsAction({ routes, asPath }));
  };

export default slice.reducer;
