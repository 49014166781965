import React, { useEffect, useState } from "react";
import { IconButton, TextField, Box } from "@material-ui/core";
import { useStyles } from "./styles";
import { FruProUpload, FruProMenuList } from "@components";
import { DOC_EXTENSIONS } from "@services/chat";
import { ACCEPTED_IMAGE_EXTENSION, CONTACT_MESSAGE } from "@constants";
import { setUsersInRoom } from "@services/chat";
import { CHAT_SIDE_BAR_KEYS, USERS_IN_ROOM_TYPE } from "./constant";
import { useDispatch, useSelector } from "react-redux";
import { setIsSendContactMessage } from "@reducers/chat";

const ENTER_KEY = "Enter";

export default function ChatActions({
  onSendMsg,
  disableSendChat,
  setOpenShareProductDialog,
  shouldDisableButton,
  currentChannel,
  messageInputRef,
  activeTab,
}) {
  const classes = useStyles();
  const [message, setMesage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const isSendContactMessage = useSelector(
    (state) => state.chat.isSendContactMessage
  );
  const dispatch = useDispatch();

  const isBroadcastTab = activeTab === CHAT_SIDE_BAR_KEYS.BROADCAST;

  // Auto focus input when selecting channel
  useEffect(() => {
    if (currentChannel?.id) {
      messageInputRef?.current?.focus();
      setMesage("");
    }
  }, [currentChannel?.id]);

  useEffect(() => {
    if (isSendContactMessage) {
      setTimeout(() => {
        handleSendMsg(CONTACT_MESSAGE);
      }, 2000);
      dispatch(setIsSendContactMessage(false));
    }
  }, [isSendContactMessage]);

  const handleInputChange = (e) => {
    const message = e.target.value;
    setMesage(message);
  };

  const handleSendFile = async (file) => {
    onSendMsg("", file);
  };

  const handleSendMsg = (messageParam) => {
    let messageSend = messageParam || message;
    if (disableSendChat) return;
    if (messageSend && messageSend.trim()?.length) {
      onSendMsg(messageSend);
      setMesage("");
    }
  };

  const handleFocus = (e) => {
    if (currentChannel?.id) {
      setUsersInRoom(currentChannel?.id, {
        type: USERS_IN_ROOM_TYPE.ADD,
      });
    }
  };

  const handleFocusOut = (e) => {
    if (currentChannel?.id && event.target != document.activeElement) {
      setUsersInRoom(currentChannel?.id, {
        type: USERS_IN_ROOM_TYPE.REMOVE,
      });
    }
  };

  const onClickUpload =
    (id = "upload_image") =>
    () => {
      document.getElementById(id).value = null;
      document.getElementById(id).click();
    };

  const onClickAttachment = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const ATTACHMENT_OPTIONS_LIST = [
    {
      icon: <img src={`/static/svg/ic_picture.svg`} />,
      label: (
        <>
          {"Send image"}
          <FruProUpload
            id="upload_image"
            name="myImage"
            accept={ACCEPTED_IMAGE_EXTENSION}
            onChange={handleSendFile}
            hidden
            multiple={true}
            maxQuantity={10}
            resize
          />
        </>
      ),
      onClick: onClickUpload("upload_image"),
    },
    {
      icon: <img src={`/static/svg/ic_upload_video.svg`} />,
      label: (
        <>
          {"Send video"}
          <FruProUpload
            id="upload_video"
            name="myVideo"
            accept="video/*"
            onChange={handleSendFile}
            hidden
            multiple={true}
            maxSize={100 * 1024 * 1024}
            maxQuantity={3}
          />
        </>
      ),
      onClick: onClickUpload("upload_video"),
    },
    {
      icon: <img src={`/static/svg/ic_folder.svg`} />,
      label: (
        <>
          {"Send file"}
          <FruProUpload
            id="upload_document"
            name="myDocument"
            accept={DOC_EXTENSIONS}
            onChange={handleSendFile}
            hidden
            multiple={true}
            maxQuantity={1}
            maxSize={25 * 1024 * 1024}
          />
        </>
      ),
      onClick: onClickUpload("upload_document"),
    },
  ];

  return (
    <>
      <Box width={isBroadcastTab ? "20%" : "11%"}>
        <IconButton
          style={{ padding: 8 }}
          onClick={onClickAttachment}
          disabled={disableSendChat}
        >
          <img src={`/static/svg/ic_attachment.svg`} alt="Attachment" />
        </IconButton>
        <IconButton
          style={{ padding: 8 }}
          onClick={onClickUpload("upload_voice")}
          disabled={disableSendChat}
        >
          <img src={`/static/svg/ic_microphone.svg`} alt="Microphone" />
          <FruProUpload
            id="upload_voice"
            name="myVoice"
            accept="audio/*"
            onChange={handleSendFile}
            hidden
            multiple={true}
            maxQuantity={1}
          />
        </IconButton>
        {isBroadcastTab && (
          <IconButton
            style={{ padding: 8 }}
            disabled={disableSendChat}
            onClick={() => setOpenShareProductDialog(true)}
          >
            <img
              src={`/static/svg/ic_share_produces.svg`}
              alt="Share produce"
            />
          </IconButton>
        )}
      </Box>
      <TextField
        className={classes.chatContent}
        inputRef={messageInputRef}
        variant="outlined"
        value={message}
        disabled={disableSendChat}
        onChange={handleInputChange}
        placeholder="Aa"
        onKeyPress={(e) => {
          // Press Shift with Enter
          if (e.shiftKey && e.key === ENTER_KEY) {
            return true;
          }
          if (e.key === ENTER_KEY) {
            handleSendMsg();
          }
        }}
        autoFocus
        onFocus={handleFocus}
        onBlur={handleFocusOut}
        InputProps={{
          endAdornment: (
            <IconButton
              style={{ padding: 12 }}
              disabled={disableSendChat}
              onClick={() => handleSendMsg()}
            >
              <img src={`/static/svg/ic_send.svg`} alt="Send icon" />
            </IconButton>
          ),
        }}
      />
      <FruProMenuList
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        items={ATTACHMENT_OPTIONS_LIST}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      />
    </>
  );
}
