import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  IconButton,
  DialogActions,
  Typography,
  Checkbox,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";
import { FruProButton } from "@components";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import { setNewChatInfo } from "@reducers/chat";
import clsx from "clsx";
import { getAvailableUsers } from "@services/user";
import { CHAT_SIDE_BAR_KEYS } from "./constant";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 800,
    },
  },
  dialogContent: {
    width: 740,
    height: 507,
    padding: 0,
  },
  contentRows: {
    overflow: "auto",
    padding: "36px 16px 0",
    "& p": {
      margin: 0,
    },
  },
  contentRow: {
    display: "flex",
    borderBottom: "1px solid #E8E8E8",
    padding: "16px 0",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.grey.light,
    },
    "& .MuiCheckbox-root": {
      padding: "0 16px",
      "& svg": {
        width: 19,
        height: 19,
      },
    },
    "&.checked": {
      backgroundColor: "#F5F2F1",
    },
  },
  contentSearch: {
    width: "94%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      height: 36,
      borderRadius: 18,
    },
  },
  dialogTitle: {
    padding: 24,
  },
  dialogTitleContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inline: {
    display: "inline",
  },
  employeeInfor: {},
  emailPhone: {
    fontSize: 15,
    color: "#858688",
    fontWeight: 500,
  },
  disabled: {
    backgroundColor: theme.palette.grey.light,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  dialogActions: {
    padding: "36px 16px",
  },
  dangerButton: {
    color: "#F6133D",
    fontSize: 12,
    fontWeight: 500,
    width: "fit-content",
    padding: 0,
    width: "4%",
  },
  textButton: {
    textTransform: "capitalize",
    textDecoration: "underline",
    fontSize: 17,
    fontWeight: "bold",
    marginRight: 28,
    minWidth: 121,
  },
  customPrimaryButton: {
    "&.MuiButton-root": {
      fontSize: 17,
      margin: 0,
      padding: "12px 16px",
      width: 153,
      maxHeight: 44,
      borderRadius: 3,
    },
  },
}));

export default function AddNewChatDialog({
  openDialog,
  handleCloseDialog,
  userId,
  handleAdd: handleAddProp,
  membersInfo = {},
  getData,
  type,
  initialQueryParams,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [keyword, setKeyWord] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const isBroadcast = type === CHAT_SIDE_BAR_KEYS.BROADCAST;

  useEffect(() => {
    if (!openDialog) {
      setSelectedUser([]);
      setKeyWord("");
    }
  }, [openDialog]);

  const handleInputChange = (e) => {
    const keyword = e.target.value;
    setKeyWord(keyword);
  };

  const handleAdd = () => {
    if (selectedUser?.length <= 0) return;
    else {
      handleCloseDialog();
      if (handleAddProp) return handleAddProp(selectedUser);
      else {
        if (selectedUser.length === 1)
          dispatch(
            setNewChatInfo({
              companyId: selectedUser[0].companyId,
              email: selectedUser[0].email,
              fullName: selectedUser[0].fullName,
              id: selectedUser[0].id,
              photo: selectedUser[0].photo,
              companySlug: selectedUser[0]?.companySlug,
              companyName: selectedUser[0]?.company?.companyName,
            })
          );
        else
          dispatch(
            setNewChatInfo(
              selectedUser.map((user) => ({
                companyId: user.companyId,
                email: user.email,
                fullName: user.fullName,
                id: user.id,
                photo: user.photo,
                companySlug: user?.companySlug,
              }))
            )
          );
      }
    }
  };

  const onChecked = (checked, user) => {
    if (checked) {
      setSelectedUser((prev) =>
        [...prev].filter(
          (curUser) => (curUser._id || curUser.id) !== (user._id || user.id)
        )
      );
    } else {
      setSelectedUser((prev) => [...prev, user]);
    }
  };

  const fetchData = async (query, callback) => {
 
      let response = {};
      setLoading(true);
      if (getData) {
        response = await getData({
          ...initialQueryParams,
          keyword: query,
        });
      } else {
        response = await getAvailableUsers({
          keyword: query,
          includes: "company",
        });
      }

      const employees = response?.items;
      callback(employees);
      setLoading(false);
    
  };

  const debouncedFetchData = useCallback(
    debounce((query, callback) => {
      fetchData(query, callback);
    }, 500),
    []
  );

  const onResetSearch = () => {
    setKeyWord("");
  };

  useEffect(() => {
   if(openDialog){
    debouncedFetchData(keyword, (res) => {
      setEmployees(res);
    });
   }
  }, [keyword,openDialog]);

  const renderUserItem = (item) => {
    let component = null;

    switch (type) {
      case CHAT_SIDE_BAR_KEYS.BROADCAST:
        component = (
          <Box className={classes.employeeInfor}>
            <Typography className="fullname fw-medium text-sm">
              {item.user.fullName}
            </Typography>
            <Box paddingTop="6px">
              <Typography className={classes.emailPhone} component="span">
                {item?.user?.companyName}
              </Typography>
              <Typography
                className={classes.emailPhone}
                style={{ padding: "0 12px " }}
                component="span"
              >
                -
              </Typography>
              <Typography className={classes.emailPhone} component="span">
                {item.user.email}
              </Typography>
            </Box>
          </Box>
        );
        break;
      default:
        component = (
          <Box className={classes.employeeInfor}>
            <Typography className="fullname fw-medium text-sm">
              {item.fullName}
            </Typography>
            <Box paddingTop="6px">
              <Typography className={classes.emailPhone} component="span">
                {item?.company?.companyName}
              </Typography>
              <Typography
                className={classes.emailPhone}
                style={{ padding: "0 12px " }}
                component="span"
              ></Typography>
            </Box>
          </Box>
        );
    }

    return component;
  };

  const renderLoading = () => (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="primary" size={20} />
    </Box>
  );

  return (
    <Dialog
      className={classes.root}
      open={openDialog}
      onClose={() => handleCloseDialog()}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.dialogTitle}
      >
        <Grid item className={classes.dialogTitleContent}>
          <Typography className="text-xl" variant="h6" align="center">
            Add user
          </Typography>
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="0 16px"
      >
        <TextField
          className={classes.contentSearch}
          variant="outlined"
          size="small"
          placeholder="Search"
          onChange={handleInputChange}
          value={keyword}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
        />
        <Button className={classes.dangerButton} onClick={onResetSearch}>
          Reset
        </Button>
      </Box>

      <DialogContent className={classes.dialogContent}>
        {loading ? (
          renderLoading()
        ) : (
          <div className={classes.contentRows}>
            {employees?.map((item) => (
              <div
                key={item._id || item.id}
                className={clsx({
                  [classes.contentRow]: true,
                  [classes.disabled]:
                    membersInfo[item?.user?.id || item._id || item.id],
                  checked: selectedUser
                    .map((user) => user.id)
                    .includes(item.id),
                })}
                onClick={() =>
                  onChecked(
                    selectedUser.some(
                      (user) => (user._id || user.id) == (item._id || item.id)
                    ),
                    item
                  )
                }
              >
                <Checkbox
                  checked={
                    !!(
                      selectedUser.some(
                        (user) => (user._id || user.id) == (item._id || item.id)
                      ) || membersInfo[item?.user?.id || item._id || item.id]
                    )
                  }
                  color="primary"
                />
                {renderUserItem(item)}
              </div>
            ))}
          </div>
        )}
      </DialogContent>
      <hr style={{ marginTop: 0, marginBottom: 0 }} />
      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.textButton}
          onClick={() => handleCloseDialog()}
          variant="text"
          color="primary"
        >
          Cancel
        </Button>
        <FruProButton
          className={classes.customPrimaryButton}
          onClick={handleAdd}
          color="primary"
          disabled={
            isBroadcast
              ? [...Object.keys(membersInfo), ...selectedUser]?.length <= 1
              : !selectedUser?.length
          }
        >
          Select
        </FruProButton>
      </DialogActions>
    </Dialog>
  );
}
