import {
  Dialog,
  Toolbar,
  IconButton,
  makeStyles,
  Box,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { blobUrl } from "utils";
import { useEffect, useState, useMemo } from "react";

const useStyles = makeStyles(() => ({
  paperFullScreen: {
    background: "rgba(0, 0, 0, 0.8)",
    '& .MuiToolbar-root': {
      justifyContent: 'flex-end'
    }
  },
  imageContainer: {
    height: "initial",
    objectFit: "scale-down",
  },
  floatBtn: {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
  },
  leftBtn: {
    left: 24,
  },
  rightBtn: {
    right: 24,
  },
}));

const isImage = (file) => {
  return file?.type?.includes("image");
};

export default function MediaSlider({
  open,
  handleClose,
  currentMedia,
  currentMediaIndex: currentMediaIndexProp,
}) {
  const classes = useStyles();
  const [currentMediaIndex, setCurrentMediaIndex] = useState();

  useEffect(() => {
    setCurrentMediaIndex(currentMediaIndexProp);
  }, [currentMediaIndexProp]);

  const selectedMedia = useMemo(() => {
    if ((!currentMedia || currentMedia?.length < 1) ?? currentMediaIndex)
      return null;
    return currentMedia[currentMediaIndex];
  }, [currentMedia, currentMediaIndex]);

  const showNextBtn = useMemo(() => {
    return (
      currentMediaIndex < currentMedia?.length - 1 && currentMedia?.length > 1
    );
  }, [currentMedia, currentMediaIndex]);

  const showPrevBtn = useMemo(() => {
    return currentMediaIndex > 0 && currentMedia?.length > 1;
  }, [currentMedia, currentMediaIndex]);

  const onNext = (isNext) =>
    setCurrentMediaIndex((index) => (isNext ? index + 1 : index - 1));

  if (!selectedMedia) return null;
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      classes={{
        paperFullScreen: classes.paperFullScreen,
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="primary"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <Box
        display="flex"
        position="relative"
        height={1}
        justifyContent="center"
        padding={1}
        overflow="hidden"
      >
        {selectedMedia ? (
          <img
            src={
              selectedMedia?.url
                ? selectedMedia?.url
                : selectedMedia?.constructor === File
                ? blobUrl(selectedMedia)
                : null
            }
            alt="Open Image"
            className={clsx(classes.imageContainer)}
          />
        ) : null}
        {showPrevBtn ? (
          <IconButton
            edge="start"
            color="primary"
            onClick={() => onNext(false)}
            className={clsx(classes.floatBtn, classes.leftBtn)}
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
        ) : null}
        {showNextBtn ? (
          <IconButton
            edge="start"
            color="primary"
            onClick={() => onNext(true)}
            className={clsx(classes.floatBtn, classes.rightBtn)}
          >
            <KeyboardArrowRight fontSize="large" />
          </IconButton>
        ) : null}
      </Box>
    </Dialog>
  );
}
